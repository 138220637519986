import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Fox grab flowchart`}</h1>
    <p>{`Fox's followups after a grab depend on your opponent's DI:`}</p>
    <p><a parentName="p" {...{
        "style": {
          "boxShadow": "none"
        },
        "href": "https://excalidraw.com/#json=5120999011909632,Y57VloPaA1LSKT4-1NTgNA"
      }}><svg parentName="a" {...{
          "role": "img",
          "aria-label": "Excalidraw drawing",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "viewBox": "0 0 726 520",
          "width": "726",
          "height": "520"
        }}>{
            /* svg-source:excalidraw */
          }<defs parentName="svg"><style parentName="defs">{`@font-face {font-family: "Virgil";src: url("https://excalidraw.com/Virgil.woff2");}@font-face {font-family: "Cascadia";src: url("https://excalidraw.com/Cascadia.woff2");}`}</style></defs><rect parentName="svg" {...{
            "x": "0",
            "y": "0",
            "width": "726",
            "height": "520",
            "fill": "#ffffff"
          }}></rect><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(10 10) rotate(0 353 250)"
          }}><path parentName="g" {...{
              "d": "M-0.42 -0.03 C251.88 1.27, 504.18 1.19, 706.67 -0.45 M-0.38 0.15 C182.48 0.59, 365.55 0.59, 706.04 -0.17 M706.58 -0.54 C705.32 191.4, 705.99 382.88, 705.93 500.74 M705.6 0.39 C705.99 117.09, 706.03 234.7, 706.05 499.83 M706.5 499.3 C462.26 501.87, 220.5 502.2, -0.47 499.23 M706.32 500.17 C460.17 498.52, 214.33 498.09, -0.26 499.82 M-0.09 499.88 C0.71 348.03, 0.58 196.9, -0.62 0.07 M0.24 499.94 C1.16 351.8, 1.63 203.85, -0.07 -0.07",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.9 1.2 L64.53 -1.53 L64.19 31.38 L1.28 31.53",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f8f9fa"
            }}></path><path parentName="g" {...{
              "d": "M1.93 0.32 C19.72 1.73, 42.88 1, 63.34 -1.01 M-0.32 -0.7 C21.23 0.3, 43.41 0.57, 63.34 -0.39 M62.84 -0.3 C65.07 8.36, 65.2 20.62, 64.56 31.15 M63.92 0.57 C64.45 7.39, 63.84 17.45, 63.95 31.47 M64.76 31.27 C44.06 31.43, 23.16 32.46, 0.86 32.19 M64.34 31.19 C42.65 31.15, 20.72 32.1, 0.08 31.92 M-0.72 32.41 C-0.82 21.62, 0.07 13.93, 0.6 -0.13 M-0.53 31.36 C0.38 19.69, -0.52 7.74, -0.9 0.77",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.49 1.21 L63.75 -1.5 L63.38 32.36 L-0.93 30.12",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f1f3f5"
            }}></path><path parentName="g" {...{
              "d": "M-1.08 -1.63 C13.06 -1.04, 24.66 -0.16, 65.17 -0.04 M-0.53 -0.86 C19.29 0.3, 37.75 -0.7, 64.35 -0.35 M63.66 1.06 C62.23 10.13, 62.63 23.06, 64.89 33.46 M63.42 -0.54 C63.75 10.31, 63.45 22.52, 63.91 32.36 M62.24 30.46 C48.7 32.27, 27.6 32.5, -1.8 33.01 M64.22 32.64 C49.65 31.17, 32.7 31.41, 0.26 32.73 M0.5 30.9 C-0.86 21, 1.3 7.73, -1.57 0.58 M0.42 32.39 C0.1 19.02, -0.05 7.74, -0.18 -0.24",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.89 -1.01 L65.38 0.81 L62.8 30.33 L-1.13 30.04",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e9ecef"
            }}></path><path parentName="g" {...{
              "d": "M1.96 -0.27 C18.25 -1.48, 31.86 0.48, 65.96 -1.22 M0.29 0.63 C12.49 -0.96, 26.14 -0.11, 63.76 -0.34 M64.97 1.45 C65.16 9.46, 64.46 16.48, 64.04 30.77 M63.29 -0.88 C64.37 11.43, 63.58 21.09, 63.94 31.91 M63.76 31.4 C41.47 33.29, 16.83 30.81, -1.59 32.4 M64.62 32.91 C46.42 31.92, 28.27 32.92, 0.41 31.09 M0.2 30.97 C-1.99 21.87, 0.66 10.05, -1.63 1.79 M-0.4 32.66 C0.69 23.26, 0.57 14.4, 0.87 -0.89",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.84 1.43 L64.39 -1.62 L64.49 33.4 L0.22 32.05",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#dee2e6"
            }}></path><path parentName="g" {...{
              "d": "M1.25 -1.62 C18.77 -1.28, 35.37 0.97, 63.55 -1.8 M-0.39 0.51 C18.33 -0.11, 37.38 0.32, 63.72 0.12 M62.42 0.63 C63.88 7.37, 65.29 17.75, 64.73 33.95 M63.28 0.07 C64.77 10.42, 63.7 21.24, 64.76 31.05 M65.44 32.94 C48.74 30.24, 31.17 30.92, 0.13 30.42 M64.3 31.17 C40.54 31.96, 16.59 31.41, -0.23 32.92 M0.47 33.24 C-0.35 23.19, 1.43 14.79, 1.02 0.18 M0.75 32.73 C-0.88 19.66, 0.56 8.41, 0.92 0.73",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.25 -0.58 L63.56 -1.24 L63.34 31.77 L-0.64 33.6",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ced4da"
            }}></path><path parentName="g" {...{
              "d": "M-1.62 -0.1 C22.11 -1.45, 42.39 -1.29, 63.28 -0.95 M-0.37 0.92 C18.62 -1.23, 37.84 -0.54, 63.37 0.93 M65.51 -0.84 C63.82 8.2, 64.44 18.07, 63.62 30.44 M63.81 0.49 C64.22 12, 63.71 25.42, 63.42 31.39 M65.92 30.17 C45.33 31.4, 29.04 32.56, -1.03 32.99 M63.25 31.21 C49.11 31.53, 35.99 31.76, 0.84 32.08 M0.38 32.94 C1.91 20.14, -1.86 7.9, 1.55 1.72 M-0.29 32.06 C-0.6 25.5, -0.79 19.53, 0.57 0.08",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.02 -0.97 L64.46 0.54 L62.08 30.81 L-1.83 32.34",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#adb5bd"
            }}></path><path parentName="g" {...{
              "d": "M-1.15 1.96 C21.91 -0.75, 45.54 -1.18, 63.63 0.54 M0.67 -0.95 C15.92 0.36, 31.52 -0.22, 64.22 0.76 M64.03 -1.82 C63.17 7.64, 63.33 11.68, 65.11 32.27 M64 -0.74 C63.84 11, 64.23 19.79, 63.13 31.56 M62.18 33.91 C45.77 34.37, 29.47 31.14, -0.9 30.42 M64.35 33 C51.06 31.58, 35.77 32.41, -0.23 32.84 M0.32 32.36 C1.94 21.34, -0.63 14.52, -1.22 1.98 M-0.47 32.61 C-0.11 20.62, 0.02 10.4, -0.97 -0.59",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.31 1.56 L64.59 0.03 L64.41 34 L-1.07 31.31",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#868e96"
            }}></path><path parentName="g" {...{
              "d": "M-0.31 -1.52 C13.76 1.21, 29.98 -1.57, 63.56 0.21 M-0.21 0.99 C23.54 0.26, 47.8 -0.88, 63.16 -0.81 M63.32 0.57 C64.14 8.35, 65.54 14.31, 62.38 31.16 M63.26 0.8 C64.45 9.56, 63.68 18.16, 63.74 31.58 M62.81 33.22 C46.59 33.59, 32.55 32.14, -0.34 31.27 M64.73 32.9 C44.38 32.8, 23.25 31.39, -0.73 31.61 M0.07 31.62 C0.2 22.37, 0.19 12.6, -0.41 1.96 M-0.2 31.03 C-0.95 23.75, -0.89 15.16, 0.95 -0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.78 -1.83 L64.49 0.1 L62.72 30.71 L-1.44 31.78",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#495057"
            }}></path><path parentName="g" {...{
              "d": "M-1.67 0.64 C15.84 -1.13, 34 1.86, 63.76 -0.69 M-0.43 -0.96 C15.61 0.77, 31.96 0.27, 63.77 0.99 M62.36 -0.78 C64.02 10.89, 65.7 20.96, 65.59 32.64 M64.33 -0.32 C64.37 6.48, 63.52 13.62, 64.5 31.91 M62.9 30.76 C38.67 32.91, 11.86 31.18, -0.08 33.02 M64.48 31.13 C42.34 31.57, 22.33 31.58, -0.2 32.93 M0.89 32.98 C-2.01 22.45, -2.12 13.28, -0.4 -0.5 M0.21 31.69 C0.92 22.22, -0.96 13.04, -0.83 -0.86",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.7 1.96 L62.77 -0.13 L62.09 33.42 L-1.41 30.41",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#343a40"
            }}></path><path parentName="g" {...{
              "d": "M0.52 -1.69 C18.75 2.11, 39.3 0.09, 62.07 1.6 M-0.58 -0.03 C19.34 0.47, 39.75 0.57, 64.77 0.52 M65.65 1.04 C62.24 10.46, 65.47 25.7, 65.44 32.58 M63.46 -0.98 C64.49 11.42, 64.66 24.5, 64.47 31.13 M65.68 33.61 C41.91 32.47, 20.7 31.38, 1.64 31.08 M64.82 32.53 C48.94 32.12, 32.19 32.13, -0.81 31.28 M0.02 33.03 C-0.54 20.84, 0.28 8.83, -0.12 -0.32 M-0.88 31.22 C-0.09 23.27, 0.74 14.72, 0.81 0.03",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 16) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.58 -1.53 L62.34 0.12 L63.14 30.17 L-0.41 31.36",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#212529"
            }}></path><path parentName="g" {...{
              "d": "M1.27 0.96 C24.05 0.19, 49.51 1.76, 62.82 -0.66 M-0.44 -0.62 C25.14 0.19, 50.22 -0.17, 63.59 0.41 M64.66 -0.28 C63.17 12.9, 65.46 24.84, 65.27 33.08 M64.68 0.41 C64.2 8.02, 64.19 16.49, 64.25 32.62 M63.08 33.76 C44.7 31.71, 23.91 30.14, 0.71 30.78 M64.1 32.88 C51.22 31.81, 36.93 32.88, -0.11 31.65 M1.95 30.67 C-1.44 25.66, 0.43 19.95, -1.77 -1.14 M0.83 31.66 C0.74 24.6, 0.4 14.84, -0.57 -0.79",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.79 1.88 L62.01 0.83 L62.07 32.35 L0.13 30.56",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fff5f5"
            }}></path><path parentName="g" {...{
              "d": "M0.29 -1 C22.78 0.46, 50.9 -0.21, 65 -0.51 M0.97 -0.19 C22.52 0.19, 45.05 -0.51, 64.88 -0.07 M64.94 -0.21 C65.69 13.37, 64.56 23.88, 64.86 30.24 M64.59 0.56 C65.17 10.17, 63.59 20.24, 64.14 31.02 M64.37 32.7 C41.2 30.72, 16 29.63, -0.55 33.96 M63.44 31.52 C48.91 32.98, 32.74 32.63, -0.42 31.32 M0.44 33.32 C-1.94 20.97, -0.09 10.7, 1.1 -1.33 M0.27 32.12 C0.47 21.09, 0.26 10.86, -0.06 0.56",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.76 0.75 L65.02 -0.51 L64.66 32.09 L-1.61 33.76",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffe3e3"
            }}></path><path parentName="g" {...{
              "d": "M1.11 1.32 C15.28 -1.04, 32.68 0.59, 64.18 0.58 M0.93 0.07 C23.54 -0.04, 47.97 0.84, 64.26 0.84 M62.83 1.78 C61.77 7.92, 64.24 11.65, 65.71 30.59 M63.04 -0.9 C63.37 9.7, 64.7 19.22, 64.27 31.46 M64.92 30.35 C43.54 31.95, 21.2 33.37, -1.07 33.15 M64.38 31.01 C40.81 31.15, 19.67 31.34, -0.37 31.75 M1.99 32.74 C-1.54 20.37, -1.38 8.32, 1.51 -1.21 M0.6 31.54 C0.18 25.16, -0.21 19.77, 0.78 -0.3",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.68 -1.67 L64.34 -0.55 L64.61 32.02 L-1.51 31.91",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffc9c9"
            }}></path><path parentName="g" {...{
              "d": "M0.98 -0.26 C17.13 -1.81, 36.18 -1.87, 63.46 0.23 M0.9 0.34 C21.31 0.18, 43.02 0.42, 64.67 0.82 M64.35 -0.9 C64.35 7.69, 63.71 13.43, 63.27 33.36 M63.5 -0.25 C64.21 7.84, 64.49 14.74, 64.92 32.11 M62.39 32.99 C41.56 30.64, 18.4 31.85, 1.06 31.02 M63.98 32.53 C49.16 31.66, 33.02 31.48, 0.91 31.4 M-1.15 31.09 C1.33 23, 0.76 14.93, -0.7 -0.34 M-0.34 32.78 C-0.69 24.59, -0.45 16.75, -0.16 -0.86",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.48 -0.42 L62.65 -1.03 L63.4 33.18 L1.07 33.78",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffa8a8"
            }}></path><path parentName="g" {...{
              "d": "M-0.11 1.7 C22.68 -1.39, 44.69 -1.17, 63.38 -0.4 M0.56 -0.01 C19.6 1.03, 38.87 -0.66, 63.02 -0.22 M65.13 -1.22 C64.77 11.69, 64.79 22.11, 65.16 31.56 M64.48 0.72 C64.26 8.01, 64.27 18.05, 64.73 32.47 M65.74 30.25 C50.13 30.69, 39.28 32.95, 0.43 33.4 M64.23 32.66 C49.76 30.97, 37.15 32.86, -0.53 31.8 M-1.3 32.79 C-0.67 22.2, 0.92 11.92, 1.94 0.83 M0.21 31.14 C0.56 24.01, 0.47 17.22, -0.29 -0.9",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.46 1.36 L62.42 0.34 L63.69 32.99 L-0.04 32.94",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ff8787"
            }}></path><path parentName="g" {...{
              "d": "M-1.72 -0.3 C14.97 0.98, 28.84 0.43, 65.95 0.77 M-0.77 0.92 C17.61 0.82, 37.07 -0.36, 64.02 -0.82 M64.59 -0.25 C63.03 7.66, 63.7 20.64, 65.39 32.62 M64.18 -0.95 C63.61 12.92, 64.33 24.23, 63.75 31.84 M65.28 33.66 C38.4 32.18, 15.8 31.56, -0.28 33.29 M63.96 32.2 C47.51 32.45, 29.7 31.61, -0.74 31.68 M-1.19 31.6 C-0.18 23.66, -0.48 18.78, -0.24 1.51 M0.06 31.98 C-0.76 23.59, -0.68 13.99, 0.08 -0.46",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.96 0.87 L62.6 -1.69 L65.72 30.43 L-1.75 33.67",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ff6b6b"
            }}></path><path parentName="g" {...{
              "d": "M1.47 1.28 C22.76 -0.01, 45.46 1.62, 63.83 -2 M0.59 -0.35 C24.67 0.02, 48.75 -1.04, 64.05 0.86 M65.04 0.85 C64.96 4.81, 65.75 11.8, 65.73 31.49 M63.46 -0.04 C65.2 12.2, 63.4 25.92, 63.93 31.05 M62.24 32.7 C43.3 34.19, 21.92 32.46, 1.2 31.51 M63.56 32.65 C49.77 32.79, 33.34 33.04, -0.98 32.66 M0.47 30.86 C1.69 21.96, 0.68 11.53, -0.28 0.05 M0.8 32.82 C0.1 20.67, -0.14 7.87, 0.03 0.46",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.17 0.25 L63.66 -1.6 L63.46 31.38 L0.51 33.63",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fa5252"
            }}></path><path parentName="g" {...{
              "d": "M0.85 -0.87 C23.27 0.23, 43.84 -0.4, 64.71 0.41 M-0.41 -0.67 C21.97 -0.42, 44.87 -1.27, 63.26 0.32 M65.8 -0.41 C65.3 10.08, 64.28 22.16, 63.69 31.32 M64.09 0.46 C64.77 11.32, 62.92 20.68, 64.78 31.75 M62.45 31.72 C47.55 33.55, 27.77 31.46, -0.09 33.85 M64.53 32.07 C49.46 31.56, 36.09 32.26, -0.4 32.35 M-1.16 31.31 C-0.89 25.16, 0.64 19.28, 0.68 -0.75 M0.24 31.21 C0.36 22.02, -1.14 11.71, 0.56 0.32",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.23 -0.31 L62.54 1.48 L63.17 30.63 L1.82 32.68",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f03e3e"
            }}></path><path parentName="g" {...{
              "d": "M-1.44 0.07 C23.75 -1.95, 45.5 0.99, 62.99 -1.58 M-0.73 0.03 C22.74 0.97, 45.43 0.04, 63.06 -0.7 M63.63 0.14 C65.47 8.29, 62.39 12.44, 62.61 30.42 M64.72 -0.21 C64.79 11.29, 64.13 21.12, 64.93 32.79 M65 32.7 C46.51 32.75, 29.88 30.76, -1.53 31.33 M64.36 31.74 C44.78 32.61, 27.33 31.13, 0.13 32.17 M1.62 31.01 C0.12 24.54, -0.81 19.47, 0.34 1.76 M-0.37 32.45 C0.7 19.66, 0.52 7.02, -0.19 0.97",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.86 0.71 L65.61 -1.3 L63.12 31.21 L-0.29 31.66",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e03131"
            }}></path><path parentName="g" {...{
              "d": "M1.86 -0.97 C23.07 2.43, 43.89 2.36, 64.54 -1.12 M0.41 -0.89 C18.72 0.48, 40.05 0.37, 63.39 0.58 M62.08 -1.6 C63.2 10.52, 65.95 22.22, 62.95 31.09 M64.99 -0.72 C64.44 8.94, 65.17 16.93, 64.76 32.88 M63.86 33.01 C46.66 30.67, 29.15 32.94, 0.33 32.25 M64.58 32.74 C47.73 31.95, 32.17 32.19, 0.99 31.64 M1.03 31.06 C1.35 19.02, 0.96 7.75, -0.07 -0.94 M-0.06 32.85 C-0.61 20.79, -0.23 10.09, -0.14 -0.01",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 54) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.68 -0.34 L64.01 -0.44 L62.21 33.6 L0.45 32.03",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#c92a2a"
            }}></path><path parentName="g" {...{
              "d": "M-0.24 1.46 C16.63 -0.56, 30.69 -0.39, 65.34 0.39 M-0.01 -0.85 C17.13 1.05, 34.9 0.23, 63.18 0.16 M63.74 -1.88 C63.09 8.01, 65.82 15.15, 63.88 30.37 M63.93 0.6 C62.91 13.19, 63.96 24.17, 63.44 32.31 M65.59 32.57 C38.55 32.8, 14.37 31.18, 1.62 31.99 M64.18 32.31 C45.61 32.07, 26.99 31.64, 0.64 31.39 M-0.6 32.64 C1.62 20.93, 0.68 7.59, -0.02 0.41 M-0.42 31.43 C0.83 21.92, -0.53 10.39, -0.19 0.8",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.48 -1.26 L64.2 1.02 L62.14 31.9 L1.25 32.8",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fff0f6"
            }}></path><path parentName="g" {...{
              "d": "M0.34 1.42 C22.5 -1.35, 48.34 -1.74, 62.82 -1.77 M-0.56 0.19 C23.67 -0.35, 48.56 -0.54, 63.16 -0.22 M62.83 1.56 C64.93 9.07, 64.51 20.82, 65.24 30.42 M64.13 -0.2 C64.44 6.52, 63.5 13.22, 64.87 31.17 M63.8 31.14 C50.24 31.63, 34.55 30.42, 1.25 32.04 M64.83 32.36 C47.56 32.39, 30.14 32.14, -0.04 31.61 M-0.37 31.64 C1.13 19.25, -1.3 9.24, -0.77 -0.53 M-0.19 32.41 C-0.61 26.13, 0.35 19.92, -0.5 0.4",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.68 0.2 L62.65 0.4 L65.5 33.62 L-1.84 33.74",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffdeeb"
            }}></path><path parentName="g" {...{
              "d": "M-0.32 -0.4 C18.92 0.62, 32.83 1.97, 62.66 1.48 M0.16 -0.74 C20.35 -0.85, 40.62 0.08, 64.97 -0.97 M62.92 1.99 C63.99 6.65, 64.57 13.94, 64.56 31.09 M64 0.49 C64.3 7.96, 64.39 14.64, 63.68 31.83 M65.3 32.44 C40.29 31.39, 18.69 33.39, -0.54 30.13 M64.3 31.73 C47.88 33.16, 33.58 33.49, -0.62 31.74 M-1.48 33.73 C1.67 22.08, -1.61 16.38, 0.71 -1.28 M0.54 31.5 C-0.41 23.7, -0.96 14.54, -0.78 0.14",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.4 -0.89 L64.11 1.05 L62.9 32.01 L0.52 32.57",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fcc2d7"
            }}></path><path parentName="g" {...{
              "d": "M-1.8 0.39 C21.96 -1.12, 45.58 -0.94, 63.41 -1.76 M-0.4 0.44 C20.69 -1.03, 41.24 0.44, 63.94 -0.49 M63.35 1.66 C64.93 10.97, 66.09 22.23, 64.76 31.04 M64.59 0.21 C63.53 9.66, 64.1 19.34, 64.82 31.51 M62.38 30.98 C49.75 29.96, 38.36 30.52, -1.56 32.82 M63.25 32.01 C42.43 32.91, 23.06 31.3, 0.65 31.1 M-0.22 31.42 C1.13 26.02, -1.22 18.2, 0.46 1.49 M0.15 32.15 C-1.18 20.95, -0.72 8.64, -0.53 -0.53",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.9 -0.09 L65.48 -1.09 L62.41 33.29 L0.08 32.96",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#faa2c1"
            }}></path><path parentName="g" {...{
              "d": "M-0.4 -0.96 C16.58 1.04, 35.78 0.55, 63.9 -0.15 M0.78 -0.28 C18.87 -0.64, 37.3 0.86, 63.21 -0.53 M63.56 -1.25 C64.47 8.77, 65.14 14.03, 62.43 32.61 M63.37 0.54 C64.2 11.51, 64.63 22.28, 63.27 31.69 M64.61 30.58 C41.98 31.08, 23.12 32.44, 1.03 30.15 M64.11 31.86 C51.99 31.64, 37.98 31.23, 0.14 31.75 M-0.75 30.02 C-0.99 24.4, -1.61 14.7, 1.08 1.1 M-0.12 32.46 C-0.55 25.16, 0.64 16.76, -0.32 -0.84",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.51 -1.89 L64.14 -1.34 L62.67 33.73 L0.81 32.41",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f783ac"
            }}></path><path parentName="g" {...{
              "d": "M1.56 0.62 C13.83 -1.3, 26.5 -1.26, 65.92 -1.92 M0.24 -0.45 C19.7 0.06, 39.14 -0.4, 64.52 -0.76 M64.61 0.79 C64.04 12.32, 64.6 23.37, 64.65 33.65 M63.79 -0.01 C63.42 7.2, 63.39 14.81, 63.69 32.14 M63.33 30.92 C38.69 33.07, 15.26 33.61, 1.59 31.01 M63.6 32.49 C43.11 33.07, 21.91 32.39, 0.85 31.59 M-0.06 31.31 C0.17 23.61, 1.16 19.19, -1.11 -0.08 M-0.31 32.93 C0.98 19.48, 1.12 7.53, 0.64 -0.83",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.15 1.93 L62.76 1.87 L62.48 33.44 L0.3 33.23",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f06595"
            }}></path><path parentName="g" {...{
              "d": "M-1.4 0.08 C12.17 -0.31, 26.09 1.21, 64.97 -1.88 M-0.41 -0.1 C22.21 0.1, 42.72 -0.92, 63.85 0.31 M64.04 1.18 C63.95 7.79, 65.91 14.03, 65.13 31.5 M64.34 0.75 C63.65 9.29, 64.09 19.36, 63.54 32.38 M63.42 33.64 C36.74 31.8, 13.07 30.72, 0.23 30.79 M64.76 32.63 C42.48 32.98, 22.38 32.01, -0.46 31.02 M0.11 31.99 C-1.35 22.61, -0.43 12.29, -1.47 1.28 M-0.25 31.54 C-0.59 23.85, 0.41 14.19, 0.09 -0.44",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.46 -0.08 L62.57 1.35 L62.98 30.36 L0.08 31.67",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e64980"
            }}></path><path parentName="g" {...{
              "d": "M1.08 -1.28 C20.1 0.65, 41.09 0.28, 65.76 -1.94 M0.11 -0.37 C16.17 -0.24, 31.53 0.58, 63.05 -0.95 M63.95 1.59 C62.21 10.64, 66.1 18.74, 64.67 32.25 M64.51 -0.59 C63.82 8.34, 63.61 15.69, 63.43 31.79 M64.45 30.19 C43.73 33.2, 26.19 34.02, -0.08 33.64 M63.61 31.94 C51.04 32.89, 36.77 32.32, -0.99 32.06 M0.56 30.25 C0.68 23.25, -0.21 13.09, -0.92 -1.33 M0.06 32.02 C-0.49 24.24, -0.69 17.54, 0.28 0.9",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.18 -0.94 L64.94 -0.47 L64.78 30.49 L-0.75 33.56",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d6336c"
            }}></path><path parentName="g" {...{
              "d": "M-1.29 -1.17 C19.7 1.59, 41.7 -0.49, 64.64 1.69 M0.96 0.76 C24.26 1.08, 49.66 0.29, 63.82 -0.77 M65.02 1.88 C65.37 7.93, 63.72 19.06, 64.76 30.22 M64.95 -0.21 C64.23 10.23, 63.71 21.4, 63.96 31.48 M65.25 31.74 C46.53 31.4, 33.22 30.38, 0.95 33.2 M63.9 31.47 C45.65 31.96, 25.2 32.8, 0.83 31.68 M1.13 31.88 C0.11 24.15, -1.13 19.54, 0.63 -0.64 M-0.54 31.74 C-0.24 23.6, -0.72 15.74, -0.71 0.29",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.22 -0.5 L65.35 -0.41 L62.83 30.65 L0.55 33.66",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#c2255c"
            }}></path><path parentName="g" {...{
              "d": "M1.61 -1.52 C21.35 -1.51, 45.39 -1.45, 65.58 -0.42 M0.76 0.93 C20.2 1.17, 38.89 0.23, 64.08 0.86 M62.79 -0.63 C64.46 5.17, 63.99 11.46, 64.71 32.85 M64.35 0.76 C64.65 9.57, 64.49 18.33, 63.42 32.17 M65.72 31.67 C37.16 34.27, 15.06 32.32, -0.7 30.97 M63.89 31.72 C38.62 33.22, 13.78 32.43, 0.76 31.14 M0.27 31.46 C0.19 23.97, 0.8 13.63, 0.79 0.24 M-0.89 32.39 C1.12 22.27, 1.04 11.4, 0.55 -0.73",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 92) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.36 -0.47 L62.28 -1.71 L63.31 33.45 L-0.69 30.66",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#a61e4d"
            }}></path><path parentName="g" {...{
              "d": "M-0.66 -1.56 C15.61 -1.11, 34.32 -2.21, 65.77 -0.85 M-0.94 0.77 C23.44 0.95, 48.59 -0.76, 63.6 -0.1 M65.9 -1.26 C63.72 13.33, 64.33 25.15, 65.65 32.66 M64.74 0.06 C63.03 7.39, 63.86 14.29, 63.29 31.79 M64.49 30.65 C46.47 33.51, 31.08 33.39, -1.79 31.02 M63.26 31.99 C49.29 33.01, 33.88 32.68, -0.02 31.03 M0.22 30.47 C0.19 21.8, 2.07 10.45, -1.15 0.11 M0.23 32.82 C-0.35 19.53, 0.24 7.81, 0.41 -0.25",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.36 1.64 L62.05 -1.95 L65.15 33.6 L-0.96 32.04",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f8f0fc"
            }}></path><path parentName="g" {...{
              "d": "M1.74 -0.93 C19.67 -0.1, 42.74 0.39, 62.18 0.11 M0.49 -0.32 C12.88 -0.47, 26.27 0.36, 64.85 -0.98 M64.38 -0.41 C65.34 12.18, 64.76 24.01, 62.5 30.04 M63.18 0.23 C64.23 10.93, 64.83 20.64, 63.29 31.91 M63.67 31.22 C40.97 30.44, 20.11 33.49, 0.3 33.15 M64.02 32.28 C39.3 32.14, 16.85 31.47, 0.29 32.59 M-0.36 33.87 C0.5 26.99, 1.76 17.36, -0.05 1.96 M-0.47 31.88 C0.41 19.41, 1.21 7.8, 0.84 0.65",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.17 1.78 L62.59 -1.33 L62.3 31.17 L0.26 32",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f3d9fa"
            }}></path><path parentName="g" {...{
              "d": "M0.54 1.45 C21.71 -1.46, 42.02 0.79, 65.81 0.32 M0.61 0.33 C24.08 1.04, 50.26 1.13, 63.16 0.77 M64.36 0.34 C65.81 7.1, 64.48 14.83, 64.07 32.73 M64.51 -0.85 C64.62 8.58, 63.33 18.45, 64.44 32.48 M63.81 32.25 C41.59 31.36, 21.03 30.13, 1.68 32.52 M63.22 31.62 C39.75 32.31, 16.08 32.02, 0.74 32.5 M-0.79 31.79 C-1.59 22.62, -1.63 17.5, -0.02 1.77 M0.45 31.46 C-0.74 19.34, 0.53 6.01, -0.03 0.03",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.95 0.59 L64.59 -0.77 L64.34 31.01 L-0.06 31.26",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#eebefa"
            }}></path><path parentName="g" {...{
              "d": "M-1.05 -1.8 C15.71 -0.25, 34.74 1.31, 65.53 1.67 M0.88 0.31 C17.26 0.16, 31.81 -0.21, 64.3 0.52 M65.43 -0.3 C61.69 6.81, 64.93 14.47, 65.55 32.04 M64.28 0.56 C63.74 6.38, 64.22 13.35, 63.12 32.57 M62.36 32.77 C47.04 30.97, 32.43 30.68, 1.57 31.6 M64.63 32.02 C50.22 32.03, 37.79 32.42, -0.49 32.57 M0.93 33.76 C-0.08 19.95, 0.55 9.92, -1.78 -1.79 M0.89 31.07 C-0.62 21, 0.06 11.9, -0.2 -0.68",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.85 0.76 L64.62 0.25 L63.6 32.61 L-1.54 32.73",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e599f7"
            }}></path><path parentName="g" {...{
              "d": "M-0.95 1.28 C23.86 -1.72, 45.98 -2.27, 64.04 -0.44 M0.22 0.29 C17.67 -0.45, 37.86 0.66, 63.12 0.12 M64.99 0.03 C62.56 8.16, 63.44 21.48, 64.77 33.73 M63.94 -0.47 C64.1 11.09, 63.2 23.13, 63.75 32.53 M65.76 31.25 C51.44 33.68, 37.18 32.99, 0.09 33.11 M64.41 31.41 C49.07 30.64, 34.5 30.9, 0.73 31.76 M-1.54 30.12 C1.17 20.93, 1.68 9.8, -1.16 -0.01 M-0.37 32.6 C-0.43 25.6, 0.52 18.66, -0.99 -0.9",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.24 -1.14 L64.72 -0.63 L63.76 30.66 L1.13 30.6",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#da77f2"
            }}></path><path parentName="g" {...{
              "d": "M-0.88 1.29 C17.53 -1.31, 36.31 -1.49, 64.94 1.17 M0.47 -0.05 C17.51 -1.03, 37.33 -0.03, 64.43 -0.88 M64.02 1.91 C65.72 10.42, 64.17 22.29, 64.61 30.65 M63.6 0.66 C64.01 7.28, 64.05 13.93, 63.26 31.63 M63.96 32.97 C42.58 30.01, 19.11 31.3, 1.52 30.69 M64.16 31.75 C39.84 32.72, 17.19 33.44, 0.17 31 M0.53 31.11 C-1.19 21.68, -0.07 12.79, -1.7 0.2 M0.05 32.62 C-0.58 20.79, -0.27 10.42, -0.74 0.31",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.73 1.18 L63.23 -0.07 L64.28 30.49 L0.18 31.95",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#cc5de8"
            }}></path><path parentName="g" {...{
              "d": "M-0.7 -0.28 C14.85 -1.74, 26.94 -0.7, 65.95 -1.63 M-0.64 -0.57 C18.03 -1.14, 36.68 0.01, 64.08 -0.62 M62.9 1.82 C65.57 14.02, 66.15 26.33, 62.91 30.97 M63.8 0.55 C64.1 7.95, 65.05 13.26, 63.96 31.04 M65.85 33.93 C49.13 32.32, 33.54 34.22, -1 32.04 M64.79 31.87 C46.48 32.27, 26.52 32.26, -0.77 32.5 M-0.95 33.29 C-1.36 17.67, 1.16 6.59, -0.18 -1.94 M-0.75 32.52 C-0.38 20.94, -0.64 8.33, -0.79 -0.69",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.07 -0.1 L64.59 0.13 L63.71 32.99 L0.05 30.09",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#be4bdb"
            }}></path><path parentName="g" {...{
              "d": "M0.72 -1.66 C14.13 0.33, 27.49 -1.24, 62.11 -1.14 M0.83 0.36 C22.73 0.58, 46.99 0.47, 64.01 0.65 M62.54 1.29 C63.74 5.03, 62.97 12.32, 63.91 30.85 M64.8 0.6 C62.92 9.38, 63.63 17.46, 64.8 31.2 M63.61 32.54 C49.6 32.99, 37.85 33.19, -1.94 31.7 M64.64 31.66 C50.92 31.88, 37.26 32.32, -0.15 31.94 M0.91 33.8 C-0.98 18.54, 1.81 10.5, 0.56 0.53 M0.41 31.92 C-0.11 19.39, -0.75 6.69, 0.04 -0.57",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.09 1.94 L63.61 1.81 L65.56 33.4 L0.33 31.03",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ae3ec9"
            }}></path><path parentName="g" {...{
              "d": "M-0.9 -1.5 C18.71 -0.45, 32.92 1.14, 62.04 -0.45 M0.83 -0.23 C17.54 -0.21, 33.86 -0.69, 64.1 -0.35 M64.78 -0.45 C62.79 7.47, 62.64 19.53, 62.8 31.57 M63.37 0.69 C63.81 13.14, 64.23 24.66, 64.31 31.57 M65.91 33.55 C52.65 30.08, 39.29 32.92, -0.04 30.83 M63.84 31.19 C49.71 31.8, 33.56 31.86, -0.99 32.54 M-0.9 31.65 C0.52 21.68, -0.94 15.64, -1.42 -1.07 M-0.05 31.72 C-0.82 19.33, 0.33 6.53, -0.69 0.62",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.19 -1.28 L63.81 -1.18 L62.48 31.94 L-0.11 31.6",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#9c36b5"
            }}></path><path parentName="g" {...{
              "d": "M0.26 1.08 C15.9 -0.18, 30.15 0.84, 64.79 -1.04 M0.28 -0.94 C18.4 -0.1, 38.2 0.55, 64.99 -0.59 M65.29 -1.53 C63.14 9.68, 63.05 16.79, 63.76 33.38 M63.79 0.75 C64.14 8.89, 63.46 17.93, 64.47 31.07 M63.7 31.52 C40.21 31.66, 14.99 30.96, 0.98 32.84 M63.86 31.05 C50.1 31.61, 36.5 30.92, -0.62 31.71 M1.86 33.29 C-1.79 24.14, 1.11 18.06, -0.55 -0.87 M0.24 32.64 C-0.4 21.84, -0.91 10.72, -0.27 0.12",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 130) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.26 0.01 L64.29 -0.91 L62.88 33.68 L1.09 30.35",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#862e9c"
            }}></path><path parentName="g" {...{
              "d": "M0.51 0.42 C18.7 1.35, 36.72 0.74, 63.91 0.84 M-0.4 0.52 C18.9 -1.28, 38.56 -1.42, 63.25 -0.85 M62.21 0.81 C62.08 12.66, 62.92 23.05, 65.89 32.91 M63.24 0.26 C64.48 13.38, 63.01 25.84, 63.86 32.8 M65.97 32.95 C39.78 30.58, 14.62 33.92, 0.88 33.29 M64.89 31.06 C48.45 31.45, 32.43 31.15, -0.85 31.46 M1.66 30.81 C-1.11 24.49, -0.55 18.39, -1.15 -0.94 M0.09 32.54 C-0.97 24.35, -0.99 16.62, 0.06 0.99",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.31 0.15 L62.99 -0.83 L62.64 31.8 L1.77 33.46",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f3f0ff"
            }}></path><path parentName="g" {...{
              "d": "M1.82 -1.36 C14.91 1.54, 29.55 -0.91, 63.11 1.23 M-0.92 -0.25 C23.75 -0.23, 49.33 0.97, 63.37 -0.33 M62.9 -1.51 C62.05 10.96, 65.17 25.53, 62.6 33.91 M63.09 0.17 C63.85 9.46, 64.05 20.18, 63.13 31.23 M62.87 31.47 C37.89 31.54, 14.47 30.63, 1.9 30.63 M63.79 31.85 C49.49 31.97, 36.71 31.82, -0.18 32.22 M-1.54 33.53 C-1.47 20.89, 2.05 9.2, -0.55 1.62 M-0.77 32.54 C-0.81 24.66, 0.31 19.18, -0.98 -0.41",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.66 -1.98 L62.08 0.39 L63.89 31.02 L-1.67 32.19",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e5dbff"
            }}></path><path parentName="g" {...{
              "d": "M0.8 -1.72 C23.91 0.24, 49.16 -0.23, 65.59 0.59 M-0.33 -0.3 C18.95 -0.54, 39.27 -0.17, 63.04 -0.14 M65.2 0.98 C65.08 7.57, 61.86 15.95, 62.81 30.65 M63.34 -0.17 C64.73 9.1, 65.29 16.85, 64.11 32.4 M62.89 32.7 C39.69 31.08, 16.64 33, 1.19 31.59 M63.91 32.39 C42 31.97, 19.7 31.11, -0.42 32.64 M-1.15 32.82 C1.63 19.9, 0.33 12.55, -0.2 -1.79 M-0.23 31.46 C0.95 23.82, 0.09 13.95, -0.99 0.73",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.65 0.01 L64 1.46 L62.23 30.19 L1.59 33.52",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d0bfff"
            }}></path><path parentName="g" {...{
              "d": "M0.05 -1.42 C14.41 0.49, 30.79 1.14, 62.99 -1.58 M-0.41 -0.22 C24.42 -0.08, 48.48 0.87, 64.3 -0.6 M63.85 0.38 C65.83 11.04, 64.17 20.15, 63.38 32.52 M64.91 -0.91 C63.73 9.32, 64.23 18.95, 63.41 32.29 M62.1 31.72 C50.66 32.28, 38.06 33.42, 1.5 31.27 M64.81 32.59 C46.98 31.77, 30.67 31.7, -0.52 31.64 M0.32 31.61 C1.74 19.56, -0.41 10.47, 1.93 -0.55 M-0.44 32.14 C0.72 24.72, 0.98 17.18, -0.54 -0.07",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.22 -0.88 L63.73 0.43 L63.81 32.23 L-1.31 30.35",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#b197fc"
            }}></path><path parentName="g" {...{
              "d": "M-0.26 -1.74 C18.86 -1.39, 39.4 -0.27, 63.22 1.76 M-0.07 -0.53 C24.05 -0.69, 46.74 -0.91, 63.71 0.24 M65.59 -1.55 C62.5 11.62, 62.39 19.66, 62.8 31.55 M65 0.98 C64.33 7.09, 64.87 14.53, 64.87 31 M62.63 32.24 C40.24 31.8, 11.54 32.64, 0.03 32.01 M63.63 31.09 C48.16 31.68, 32.72 31.98, -0.13 32.72 M-1.46 33.7 C-1.11 21.38, 2.14 8.84, -1.96 -1.63 M-0.64 31.87 C0.34 22.6, 0.42 13.94, -0.61 -0.23",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.65 -0.56 L64.44 1.05 L64.83 33.83 L1.97 31.53",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#9775fa"
            }}></path><path parentName="g" {...{
              "d": "M1.89 -0.33 C21.96 -1.91, 46.44 0.4, 64.35 -1.46 M0.2 -0.22 C19.44 -0.1, 37.16 0.44, 63.4 0.66 M64.04 0.17 C62.64 8.69, 63.66 16.58, 64.05 31.45 M63.7 0.7 C64.98 6.77, 63.35 14.44, 63.34 32.66 M65.11 30.95 C43.9 30.08, 25.53 30.97, -1.04 32.68 M64.47 31.39 C50.38 30.7, 38.93 31.78, 0 32.91 M-1.43 32.26 C0.79 19.9, -1 12.4, -1.18 -1.89 M-0.74 31.9 C-0.65 25.51, 0.81 19.55, 0.72 0.65",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.15 -0.41 L62.65 0.78 L64.33 33.83 L1.99 30.69",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#845ef7"
            }}></path><path parentName="g" {...{
              "d": "M-0.83 1.67 C20.49 0.73, 45.21 0.46, 63.53 -1.27 M-0.43 -0.64 C13.39 -0.34, 27.77 -0.01, 64.17 -0.5 M64.09 1.24 C65.67 8.57, 62.74 17.68, 65.57 30.32 M63.92 -0.13 C63.77 11.12, 63.23 23.64, 64.07 31.3 M63.83 30.7 C40.45 32.77, 20.61 30.88, -1.49 31.04 M64.89 32.37 C41.57 32.1, 20.07 31.61, -0.99 32.13 M1.47 31.59 C-1.77 22.25, 1.17 10.75, -0.91 -1.9 M0.8 31.3 C0.85 22.73, 0.51 13.74, 0.61 0.67",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.05 0.6 L65.92 0.27 L65.09 31.64 L-1.49 31.99",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#7950f2"
            }}></path><path parentName="g" {...{
              "d": "M0.03 1.53 C22.31 -2.11, 47.39 1.42, 63.51 -1.42 M0.51 0.88 C19.52 -0.99, 39.61 0.7, 65 0.67 M64.17 0.41 C65.96 10.48, 65.85 23.39, 63.56 31.8 M64.3 -0.48 C64.76 9.06, 64 17.93, 63.59 31.17 M62.66 32.33 C45.93 32.68, 25.58 31.05, -1.68 31.5 M63.09 31.36 C43.39 32.2, 24.09 30.88, 0.37 31.53 M1.39 32.99 C-0.56 21.46, 2.19 9.98, 0.3 -0.97 M0.65 32.87 C-0.23 23.82, 0.29 14.52, -0.41 0.34",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.45 0.91 L62.47 -0.86 L64.26 32.57 L-0.94 30.8",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#7048e8"
            }}></path><path parentName="g" {...{
              "d": "M1.42 -1.7 C13.41 -1.53, 30.16 0.44, 65.16 1.78 M0.01 0.94 C24.59 0.26, 48.82 0.07, 64.67 0.84 M65.05 1.16 C63.05 9.11, 65.68 18.87, 62.92 31.65 M64.85 -0.65 C64.42 12.73, 63.55 25.08, 64.65 32.65 M65.34 33.25 C50.09 30.79, 36.09 30.21, 0.3 33.76 M63.52 32.32 C50.9 33.12, 36.24 33.1, 0.3 32.63 M0.67 31.2 C1.75 21.91, -1.73 11.58, 1.19 -1.94 M0.26 31.63 C-0.05 24.76, -0.69 17.16, -0.93 -0.59",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.75 -0.01 L65.3 -0.5 L65.95 30.68 L0.33 31.09",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#6741d9"
            }}></path><path parentName="g" {...{
              "d": "M0.34 1.92 C22.62 -0.19, 49.64 -0.75, 63.4 1 M0.01 0.13 C23.12 0.59, 46.24 -0.5, 64.88 0.15 M64.37 1.1 C65.1 9.19, 62.51 21.16, 65.05 30.28 M63.63 0.65 C64.99 12.85, 63.72 23.93, 64.41 32.2 M65.88 33.79 C51.16 33.81, 35.28 32.25, 0.51 33.7 M63.86 32.11 C42.97 33, 23.74 32.15, 0.09 32.39 M1.38 31.82 C1.4 23.22, 1.73 14.63, -1.89 0.86 M-0.48 31.38 C-0.65 18.46, 0.65 6.45, -0.07 -0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 168) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.76 0.52 L62.97 1.99 L65.4 31.35 L0.86 33.34",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#5f3dc4"
            }}></path><path parentName="g" {...{
              "d": "M0.69 1.83 C14.59 -0.72, 30.68 -0.31, 65.63 -1.45 M-0.31 0.22 C21.55 0.88, 41.91 0.65, 64.8 0.14 M62.1 1.44 C64.56 5.77, 62.07 13.29, 63.57 33.76 M64.57 -0.29 C64.3 7.56, 63.82 13.84, 63.66 31.23 M65.52 31.87 C42.96 31.09, 20.54 33.52, 1.49 31.45 M63.3 32.93 C43.21 32.25, 22.79 31.63, -0.51 32.34 M-0.42 33.67 C-1.76 23.36, -0.26 19.85, -1 0.56 M-0.76 31.72 C0.37 23.71, 0.02 14.87, 0.12 0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.09 0.46 L65.97 -1.94 L62.78 32.12 L-1.9 32.42",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#edf2ff"
            }}></path><path parentName="g" {...{
              "d": "M1.34 1.44 C20.95 1.33, 37.04 0.9, 63.59 -1.68 M0.04 -0.18 C12.64 -0.11, 25.48 -0.11, 63.57 -0.57 M62.81 1.04 C66.03 9.05, 62.52 14.47, 62.55 31.59 M64.46 0.89 C63.5 7.75, 63.77 14.91, 63.5 31.43 M65.04 31.61 C39.71 33.4, 15.26 33.21, 0.63 30.83 M64.99 31.51 C39.52 30.78, 13.03 32.09, 0.4 32.51 M1.64 33.47 C2.13 21.23, -0.93 6.44, 0.66 0.85 M0.45 32.44 C-0.93 22.23, -0.06 10.23, 0.09 -0.43",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.86 0 L64.31 0.92 L63.94 32.57 L-1.81 31.49",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#dbe4ff"
            }}></path><path parentName="g" {...{
              "d": "M-1.14 -1.96 C20.61 1.75, 36.44 -1.53, 64.23 -0.01 M0.17 -0.2 C20.81 -0.19, 39.84 0.15, 64.35 0.93 M64.77 -0.89 C65.69 8.11, 65.67 18.78, 64.08 31.84 M63.02 0.52 C63.34 9.61, 63.62 19.95, 64.71 32.96 M64.91 32.69 C50.06 31.69, 34.1 33.79, 1.79 32.79 M64.49 32.11 C49 31.97, 32.96 32.3, 0.46 32.63 M-1.5 31.12 C-0.01 19.48, -0.67 9.24, 0.1 0.75 M0.8 31.67 C0.32 22.25, 0.71 12.29, 0.82 -0.25",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.36 0.54 L63.57 1.33 L63.82 30.09 L0.47 33.46",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#bac8ff"
            }}></path><path parentName="g" {...{
              "d": "M1.86 1.81 C16.93 0.32, 35.84 -0.11, 64.92 -1.99 M0.93 0.39 C18.59 -0.23, 36.19 -0.59, 63.29 0.6 M63.37 -1.52 C63.68 12.21, 65.05 24.32, 64.04 30.81 M63.2 -0.86 C64.56 9.17, 64.85 19.99, 64.07 31.89 M64.77 30.46 C47.64 33.07, 31.19 33.43, -1.75 30.54 M64.26 31.78 C40.72 31.79, 18 31.88, -0.58 32.55 M1.09 33.78 C1.32 22.08, 1.51 11.49, 1.75 0.62 M-0.76 31.13 C0.19 24.04, 0.89 15.33, -0.91 0.28",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.75 1.58 L63.08 -0.08 L62.86 30.27 L1.7 32.65",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#91a7ff"
            }}></path><path parentName="g" {...{
              "d": "M-0.6 0.85 C26.11 -0.13, 50.37 0.35, 65.54 1.88 M0.5 0.9 C17.35 -0.09, 35.41 0.84, 63.98 -0.26 M63.57 0.04 C64.41 10.77, 62.27 19.4, 65.24 30.31 M64.64 0.2 C64.35 13.35, 63.69 24.66, 63.69 31.56 M64.42 30.18 C49.36 30.88, 36.24 31.24, 0.51 31.19 M64.67 32.58 C50.1 30.85, 35.65 31.09, -0.1 32.21 M0.83 31.79 C-1.8 20.27, 0.63 6.74, 0.76 -1.03 M-0.17 32.88 C-0.82 20.39, -0.53 8.15, 0.93 0.48",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.12 -0.55 L62.87 -0.05 L64.54 33.12 L-1.18 33.71",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#748ffc"
            }}></path><path parentName="g" {...{
              "d": "M-0.56 -1.51 C22.98 -1.11, 48.84 -1.12, 65.37 1.33 M-0.55 -0.56 C13.86 0.2, 25.63 -0.23, 63.79 0.22 M63.59 -0.34 C63.74 11.37, 64.83 21.09, 63.44 31.44 M63.03 -0.87 C63.3 9.96, 64.04 20.1, 64.4 31.41 M62.7 33.8 C40.02 31.68, 15.61 31.16, 0.06 32.67 M64.65 32.35 C44.69 31.07, 23.75 31.24, 0.3 32.04 M1.73 32.42 C0.45 22.43, -1.49 14.74, -1.9 -1.82 M-0.3 31.96 C-0.11 20.73, 1.16 9.82, 0.43 -0.11",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.82 -1.38 L65.05 -0.75 L62.05 33.07 L-0.14 33.79",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#5c7cfa"
            }}></path><path parentName="g" {...{
              "d": "M-1.02 0.03 C17.73 -1.4, 33.96 -1.57, 65.54 1.4 M0.02 0.29 C16.36 -0.8, 33.83 0.37, 64.21 -0.34 M62.6 -0.96 C64.4 10.45, 64.36 26.08, 62.77 32.12 M63.26 0.79 C64.27 11.84, 64.31 22.5, 64.21 31.4 M65.91 32.86 C42.5 30.44, 16.35 31.47, -1.3 32.9 M64.86 32.96 C47.21 32.6, 31.69 32.18, 0.34 32.3 M0.97 30 C1.33 20.71, 0.11 9.23, 0.98 -0.1 M-0.6 31.02 C0.16 22.02, -0.48 9.55, 0.84 0.55",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.08 -0.72 L65.05 0.77 L65.61 30.08 L1.08 30.32",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#4c6ef5"
            }}></path><path parentName="g" {...{
              "d": "M0.02 0.21 C17.58 0.96, 33.06 -0.95, 63.24 1.59 M0.86 -0.12 C26.12 0.14, 50.85 -0.14, 64.47 0.04 M63.7 -1.91 C62.26 8.95, 63.1 18.94, 62.45 30.2 M64.78 -0.77 C64.23 6.03, 63.56 13.78, 63.12 31.47 M62.69 31 C43.39 31.89, 25.39 30.03, -1.54 32.94 M64.25 32.66 C44.6 31.37, 25.2 32.14, -0.03 32.31 M-1.33 32 C1.95 18.88, -1.27 7.12, 1.75 -1.49 M0.72 31.02 C-0.79 19.7, -0.56 7.94, -0.05 0.19",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.98 0.49 L64.31 1.76 L64.42 31.49 L1.8 30.53",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#4263eb"
            }}></path><path parentName="g" {...{
              "d": "M-0.84 0.15 C15.62 1.63, 33.56 1.12, 64.07 -1.31 M-0.66 -0.47 C13.84 -0.6, 27.22 0.22, 64.02 0.83 M62.11 0.93 C65.37 9.38, 64.48 19.08, 64.87 32.5 M64.55 0.85 C63.32 10.9, 63.17 22.61, 64.5 32.85 M65.84 32.9 C45.28 33.48, 27.3 31.62, -1.8 30.85 M63.05 31.86 C41.75 32.82, 17.95 32.35, 0.05 32.85 M-1.58 33.66 C1.61 19.61, -0.12 9.9, -0.35 -1.98 M0.17 31.72 C0.77 21.32, 1.06 11.98, 0.19 -0.06",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.97 1.98 L65.05 -0.19 L65.61 33.98 L1.47 30.14",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#3b5bdb"
            }}></path><path parentName="g" {...{
              "d": "M0.97 -1.7 C13.5 2.23, 28.21 -0.66, 64.01 1.72 M-0.94 -0.42 C22.59 -1.36, 45.52 -0.51, 64.36 0.92 M63.57 0.46 C62.54 11.1, 64.05 22.05, 63.3 32.63 M63.36 -0.51 C64.04 10.98, 63.74 21.93, 63.12 31.12 M65.29 32.96 C48.67 30.33, 30.88 31.55, -0.57 30.83 M64.9 32.19 C47.24 31.31, 31.37 31.69, 0.81 32.86 M-1.02 32.29 C0.83 19.73, -1.01 12.17, 1.97 1.77 M0.18 31.95 C-0.48 24.71, -0.53 17.76, 0.76 -0.05",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 206) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.14 1.8 L63.02 0.94 L64.24 31.19 L0.42 32.81",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#364fc7"
            }}></path><path parentName="g" {...{
              "d": "M1.35 -0.28 C22.72 0.13, 49.18 -1.53, 64.07 -0.85 M0.33 -0.84 C24.63 -0.42, 50.79 0.02, 63.33 -0.36 M65.31 -0.77 C63.32 5.24, 65.71 13.77, 64.6 30.99 M63.78 0.16 C63.04 11.42, 63.85 20.81, 63.9 31.43 M64.86 31.37 C43.23 30.69, 26 32.96, -0.1 33.4 M64.95 31.78 C39.01 33, 13.68 31.7, -0.21 31.55 M-0.01 32.25 C0.7 22.39, -1.25 13.03, 1.6 1.86 M-0.67 31.98 C0.25 22.36, 0.11 12.99, 0 0.41",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.27 0.85 L64.29 1.8 L65.39 33.21 L1.75 33.62",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e7f5ff"
            }}></path><path parentName="g" {...{
              "d": "M1.47 -1.08 C26.79 -0.43, 49.41 1.52, 63.73 -0.38 M0.32 0.78 C16.48 0.17, 33.92 -0.6, 64.16 0.73 M64.76 -0.42 C62.28 9.77, 63.52 18.16, 63.39 31.18 M63.17 0.6 C62.93 9.8, 63.67 18.42, 63.41 32.22 M65.72 32.54 C49.43 29.97, 35 33.56, 1.4 33.53 M64.07 32.16 C48.3 32.57, 33.14 31.51, -0.29 31.77 M-1.77 32.63 C0.58 23.22, -0.53 15.69, 0.06 1.91 M0.31 31.86 C-0.24 24.39, 0.13 17.31, -0.88 0.12",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.58 -1.46 L62.45 -1.97 L63.82 30.84 L0.15 33.5",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d0ebff"
            }}></path><path parentName="g" {...{
              "d": "M-1.89 -1.67 C14.53 -0.39, 27.47 0.09, 62.28 1.22 M-0.15 -0.88 C16.41 0.39, 33.03 -0.13, 64.37 0.51 M63.79 -1.95 C64.11 11.51, 62.32 23.03, 63.01 33.12 M64.56 -0.67 C64.27 8.64, 64.48 18.29, 63.16 31.14 M64.49 33.25 C44.57 33.79, 23.5 34.29, 1.97 30.05 M63.75 32 C39.67 30.99, 17.71 31.28, -0.05 31.9 M-0.03 32.94 C0.4 23.14, 0.27 14.81, -0.76 1.19 M0.78 32.16 C-0.71 24.4, -0.85 17.27, 0.27 0.66",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.29 -0.82 L64.33 -0.16 L62.05 32.38 L1.86 31.91",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#a5d8ff"
            }}></path><path parentName="g" {...{
              "d": "M-0.47 1.76 C20.36 1.07, 45.46 1.21, 64.23 1.27 M0.38 -0.49 C13.47 -0.45, 28.23 0.13, 64.37 0.42 M62.02 1.67 C65.41 8.35, 64.6 12.52, 65.57 32.87 M63.1 0.18 C64.68 11.09, 64.89 23.62, 63.51 32.97 M63.1 30.85 C42.25 30.58, 19.4 32.02, 0.51 33.57 M64.43 32.39 C38.57 32.07, 14.85 33.07, 0.77 32.37 M0.74 30.51 C-0.22 19.77, -1.2 9.29, -0.41 1.6 M-0.75 32.51 C-0.62 24.4, 0.44 15.97, -0.15 0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.43 -0.86 L62.12 0.9 L62.53 30.68 L-0.61 31.85",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#74c0fc"
            }}></path><path parentName="g" {...{
              "d": "M-1.38 1.44 C22.65 1.24, 46.86 0.52, 64.14 -0.36 M-0.82 0.43 C18.39 -0.34, 38.4 0.53, 64.18 0.21 M64.2 -0.19 C63.19 11.48, 64.29 20.72, 62.1 33.98 M64.01 -0.82 C63.38 7.57, 64.61 16.98, 64.68 31.1 M65.39 33.89 C47.53 30.13, 32.02 30.78, 0.44 31.42 M63.34 32.58 C41.9 31.18, 20.47 31.27, 0.35 32.05 M-1.68 32.62 C1.79 20.84, -0.52 6.95, -0.48 -0.96 M0.4 32.79 C0.01 20.52, -0.2 9.99, 0.37 -0.92",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.56 -1.74 L63.68 1.87 L65.9 33.42 L-0.74 32.01",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#4dabf7"
            }}></path><path parentName="g" {...{
              "d": "M-1.52 1.18 C22.53 -1.66, 48.72 1.41, 64.69 -0.1 M-0.46 0.49 C22.51 -1.22, 44.24 -0.22, 64.17 -0.19 M63.45 -1.36 C63.23 8.86, 65.13 17.04, 63.57 32.48 M63.74 -0.72 C63 7.41, 63.77 13.8, 63.87 31.58 M63.51 32.05 C47.2 34.13, 32.98 33.9, 1.68 31.73 M64.53 32.25 C39.42 32.59, 13.97 31.35, 0.42 32.06 M-0.39 30.72 C1.62 22.19, -0.38 9.05, -1.14 0.9 M-0.17 31.49 C-0.44 25.59, 0.76 17.83, -0.3 -0.77",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.53 -1.8 L63.44 -1.92 L65.7 32.53 L-0.59 31.65",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#339af0"
            }}></path><path parentName="g" {...{
              "d": "M0.68 0.24 C13.67 1.63, 28.35 0.48, 64 -1.3 M-0.92 -0.25 C24.53 0.49, 47.43 0.34, 64.08 0.55 M63.6 -0.36 C63.33 8.73, 65.58 19.72, 64.29 33.98 M63.68 -0.65 C63.43 9.06, 64.59 20.13, 64.56 31.04 M62.36 30.44 C43.54 30.27, 21.83 30.49, -0.54 31.04 M63.11 32.13 C45.62 31.36, 25.72 31.75, -0.24 31.47 M-0.71 30.21 C-0.4 18.71, 0.36 9.36, -0.39 0.71 M-0.85 31.33 C-0.37 23.89, -0.25 14.39, -0.08 -0.38",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.27 0.95 L62.06 1.83 L63.39 30.61 L-1.88 32.79",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#228be6"
            }}></path><path parentName="g" {...{
              "d": "M1.74 0.56 C22 -1.42, 43.19 1.89, 62.48 0.28 M-0.14 0.62 C13.21 -0.51, 26.76 0.06, 63.58 0.25 M63.43 0.71 C65.02 8.75, 62.53 17.81, 63.35 32.73 M64.96 -0.58 C63.67 8.77, 63.97 19.4, 64.72 31.55 M65.42 32.95 C40.65 31.49, 18.36 30.8, -1.86 32.82 M63.27 32.63 C47.79 32.95, 32.16 32.19, 0.71 31.13 M1.3 32.1 C0.13 18.96, 1.43 6.9, 0.53 1.36 M0.11 32.44 C-0.59 22.92, -0.05 15.24, 0.68 -0.14",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.45 0.73 L62.11 -0.13 L65.81 30.34 L1.31 31.18",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#1c7ed6"
            }}></path><path parentName="g" {...{
              "d": "M0.63 -1.39 C21 0.85, 37.93 2.42, 63.79 -0.72 M0.64 -0.94 C22.9 -1.28, 45.02 -0.4, 63.86 0.92 M65.59 -0.5 C62.76 12.09, 64.73 20.82, 64.75 30.43 M63.16 -0.34 C64.1 8.25, 63.84 16.93, 64.64 32.78 M63.08 32.9 C47.41 31.27, 27.32 33.16, 1.74 31.49 M64.02 32.72 C42.05 32.18, 19.68 30.65, 0.4 32.96 M1.16 31.9 C-1.48 18.06, 2.15 5.85, 1.18 1.61 M-0.73 32.11 C0.12 23.67, -0.39 14.54, -0.5 0.59",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.26 1.22 L63.13 -0.05 L64.68 33.79 L0.98 32.67",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#1971c2"
            }}></path><path parentName="g" {...{
              "d": "M-0.05 1.26 C18.83 1.47, 35.72 1.75, 64.36 0.64 M-0.42 0.51 C18.66 -0.26, 37.08 0.25, 63.09 0.02 M65.7 0.66 C65.88 13.93, 62.41 25.04, 62.91 30.16 M64.85 0.68 C63.98 12.1, 63.63 25.45, 63.23 32.75 M64.58 32.19 C48.4 31.89, 29.57 30.39, 1.07 33.34 M64.85 32.94 C43.95 33.03, 25.83 31.6, -0.89 32.95 M-0.97 31.62 C1.46 24.6, 1.75 16.23, 1.74 -1.86 M0.45 31.75 C-0.83 23.39, -0.89 14.76, 0.25 -0.13",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 244) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.22 -0.64 L65 0.12 L63.04 30.23 L-1.79 30.08",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#1864ab"
            }}></path><path parentName="g" {...{
              "d": "M-1.21 1 C17.31 -0.57, 36.27 -1.38, 62.44 0.78 M0.3 0.3 C23.5 1.36, 48.18 0.31, 63.75 0 M65.39 -0.3 C63.01 9.24, 64.61 19.88, 62.92 33.31 M64.39 -0.52 C64.95 10.58, 63.31 22.02, 64.92 31.15 M65.27 32.96 C45.61 29.82, 26.35 32.66, 1.7 31.97 M63.27 32.73 C42.78 31.34, 22.22 32.04, 0.12 32.68 M1.37 32.47 C-1.44 20.53, -1.37 8.88, -1.31 1.83 M0.75 32.72 C0.91 22.53, 0.57 10.82, 0.32 -0.81",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.87 -0.05 L62.64 -1.9 L62.93 30.52 L-0.92 33.48",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e3fafc"
            }}></path><path parentName="g" {...{
              "d": "M1.65 -0.51 C17.15 -2.11, 33.22 -1.49, 64.75 1.26 M0.85 0.32 C24.9 -0.33, 49.52 0.77, 64.26 -0.45 M62.24 -0.79 C64.82 8.7, 66.14 12.63, 63.08 30.98 M64.44 -0.78 C63.48 7.2, 65.07 14.01, 64.82 31.32 M63.67 30.87 C40.27 33.96, 18.26 31.87, -0.82 30.82 M64.61 33 C42.91 31.44, 21.52 32.53, -0.85 31.4 M1.61 32.49 C0.57 26.2, 1.73 16.93, 1.04 1.19 M-0.01 32.24 C0.49 19.36, 0.25 7.22, 0.01 -0.57",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.48 1.77 L65.81 -0.77 L65.61 30.69 L-1.58 32.95",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#c5f6fa"
            }}></path><path parentName="g" {...{
              "d": "M0.64 0 C14.11 -1.71, 30.69 0.98, 62.52 -0.12 M0.62 -0.11 C20.95 -0.79, 40.24 -0.95, 64.24 -0.93 M63.11 -0.57 C64.57 8.44, 63.02 17.73, 63.19 31.04 M64.89 0.8 C65.04 7.96, 64.88 16.42, 64.03 31.1 M62.25 32.72 C52.06 33.56, 37.91 31.72, -1.99 33.38 M63.16 32.61 C39.77 31.91, 14.83 31.34, 0.07 32.33 M0.33 33.88 C-1.28 23.9, 1.33 11.41, 1.57 -1.88 M-0.57 31.92 C-0.66 22.18, 1.2 12.94, 0.84 0.84",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.34 -0.96 L62.72 1.13 L62.78 30.79 L-1.33 32.55",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#99e9f2"
            }}></path><path parentName="g" {...{
              "d": "M0.5 -0.01 C27.34 1.77, 49.25 -0.68, 65.64 0.77 M0.17 0.39 C14.45 -0.58, 30.62 -0.62, 64.4 0.85 M65.56 0.65 C64.22 11.27, 64.49 23.88, 62.06 31.68 M63.32 0.94 C64.62 7.54, 63.36 16.27, 63.87 32.04 M65.51 30.26 C47.95 32.4, 35.75 31.42, -0.04 30.46 M64.75 32.5 C47.94 33.18, 31.34 31.87, -0.04 31.18 M1.48 31.09 C-1.01 24.2, 0.92 16.31, -1.17 -1.57 M-0.96 32.19 C0.49 21.81, 0.68 12.32, -0.56 0.96",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.29 1.66 L63.64 -0.81 L64.35 33.67 L-0.73 33.32",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#66d9e8"
            }}></path><path parentName="g" {...{
              "d": "M-0.06 1.62 C18.78 1.49, 35.62 -0.54, 64.31 -1.78 M-0.58 -0.07 C13.89 -0.79, 27.15 0.45, 63.45 0.27 M62.01 -0.29 C66.02 8.76, 62.82 19.6, 62.08 31.43 M63.7 -0.96 C63.72 12.57, 64.08 23.46, 64.21 32.2 M63.41 30.5 C44.7 32.22, 24.52 34.16, -1.17 33.92 M64.06 31.15 C48.32 30.88, 31.69 30.86, -0.29 31.97 M0.49 31.54 C0.88 23.87, 0.72 11.1, 0.32 -1.38 M-0.52 31.24 C0.71 24.19, -0.17 16.73, 0.76 -0.91",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.84 -0.78 L64.31 0.83 L62.1 31.53 L-0.98 33.48",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#3bc9db"
            }}></path><path parentName="g" {...{
              "d": "M-1.78 -1.16 C21.42 -1.47, 41.32 0.85, 62.25 -1.55 M-0.84 0.14 C16.05 1.04, 31.04 -0.54, 63.46 -0.74 M65.81 -1.61 C65.81 12.02, 64.83 23.88, 62.99 30.07 M63.75 -0.8 C64.26 7.21, 63.2 13.17, 63.1 31.05 M65.09 33.75 C48.24 31.21, 31.09 30.84, 1.87 31.55 M64.73 32.75 C43.07 31.48, 21.89 32.76, 0.06 31.3 M-1.12 31.36 C-1.01 21.65, -1.35 8.38, 0.76 -1.17 M0.85 31.37 C0.36 20.99, 0.77 10.86, 0.76 -0.56",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.94 1.74 L65.16 -0.97 L65.13 32.64 L-0.87 31.02",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#22b8cf"
            }}></path><path parentName="g" {...{
              "d": "M1.49 1.55 C17.52 -1.63, 34.87 -1.52, 62.34 1.99 M-0.65 0.39 C22.51 -0.74, 43.3 0.37, 63.56 -0.81 M63.55 -0.43 C64.8 8.56, 62.48 15.61, 62.85 32.61 M64.17 -0.18 C64.81 9.86, 64.92 22.17, 63.24 31.06 M65.21 31.89 C49.57 32.07, 37.63 32.97, -1.81 33.98 M64.66 31.42 C51.73 32.61, 38.19 32.3, 0.19 32.22 M-0.03 33.9 C-1.25 23.02, -1.23 9.19, -1.83 -1.25 M-0.16 32.72 C-1.07 21.92, 0.77 10.73, 0.77 0.29",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.6 -0.7 L65.17 -1.18 L65.76 31.33 L-1.27 33.02",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#15aabf"
            }}></path><path parentName="g" {...{
              "d": "M-1.01 1.69 C25.2 1.77, 46.61 -1.61, 62.49 -0.02 M0.53 -0.83 C18.67 0.77, 38.5 0.37, 63.62 0.38 M63.78 -1.99 C65.57 12.28, 66.11 20.9, 65.14 31.32 M64.38 0.16 C65.29 8.87, 63.68 17.95, 63.91 31.68 M64.23 30.58 C45.86 30.55, 27.29 30.99, -0.71 31.13 M64.57 32.88 C43.09 32.83, 24.17 32.79, 0.15 32.57 M0.42 33.84 C0.51 23.14, 1.01 16.2, 0.32 1.24 M-0.39 32.77 C-0.06 22.41, -0.21 12.92, -0.1 -0.65",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.13 1.78 L63.81 1.63 L65.21 31.73 L-1.9 30.7",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#1098ad"
            }}></path><path parentName="g" {...{
              "d": "M0.8 -0.64 C19.34 0.55, 38.86 1.13, 63.95 0.86 M0.51 -0.59 C23.75 1.29, 48.24 0.07, 63.63 -0.9 M65.16 1.02 C61.97 13.67, 64.74 22.56, 64.91 32.48 M64.76 -0.26 C64.75 9.47, 65.04 19.38, 63.95 31.94 M64.74 32.28 C41.91 32.21, 18.58 29.71, 1.85 31.67 M64.3 32.73 C48.16 32.06, 32.32 31.45, -0.07 32.1 M-1.31 30.82 C1.37 26.72, 0.73 17.3, 1.25 -1.11 M0.67 32.26 C0.56 23.58, -0.05 15.59, -0.27 0.07",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.37 0.25 L62.27 -1.67 L64.04 31.75 L1.93 33.75",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#0c8599"
            }}></path><path parentName="g" {...{
              "d": "M1.66 -0.27 C17.04 0.47, 28.92 -0.97, 63.17 -1.05 M0.39 -0.07 C16.91 -0.28, 34.09 -0.42, 63.3 -0.9 M65.32 0.78 C62.88 8.05, 65.63 13.15, 64.2 33.04 M63.79 0.88 C64.08 8.17, 63.89 18.96, 63.86 32.56 M65.88 31.31 C45.44 30.63, 31.32 30.43, 1.26 30.82 M63.03 32.71 C46.91 31.34, 28.53 31.83, -0.63 32.17 M1.42 33.25 C0.1 25.22, -1.85 15.36, -1.73 0.71 M-0.18 32.9 C0.2 23.9, -1.1 14.4, 0.82 -0.55",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 282) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.44 -0.61 L62.76 0.02 L63.31 32.32 L-1.62 32.87",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#0b7285"
            }}></path><path parentName="g" {...{
              "d": "M-1.78 0.94 C19.78 0.68, 39.5 -0.2, 64.76 -0.54 M-0.23 0.05 C18.72 0.58, 37.76 0.84, 63.36 -0.94 M64.97 1.92 C63.74 7.45, 63.14 15.99, 63.02 33.87 M63.42 -0.33 C64.75 8.48, 63.33 15.82, 63.55 31.51 M64.52 32.99 C45.56 32.3, 31.14 33.14, 1.52 30.15 M63.69 31.52 C40.8 31.55, 18.79 31.19, -0.95 31.56 M-1.72 30.38 C-1.4 25.86, -1.37 17.35, 1.5 1.06 M0.78 32 C0.42 26.08, 0.18 18.38, -0.82 -0.72",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.4 -0.36 L63.78 -1 L62.94 30.64 L-0.57 32.25",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e6fcf5"
            }}></path><path parentName="g" {...{
              "d": "M-1.06 -1.59 C19.39 -1.36, 43.06 -0.08, 65.74 -1.55 M0.44 -0.72 C24.55 0.02, 48.29 1.43, 63.92 0 M62.27 -1.84 C63.03 8.88, 62.76 17.89, 65.92 32.39 M64.16 -0.72 C63.01 8.47, 64.01 16.99, 64.96 31.32 M65.59 33.83 C47.19 30.4, 25.93 32.87, -0.29 31.58 M63.18 32.79 C49.62 31.19, 35.47 30.95, -0.78 32.88 M0.81 31.82 C1.9 21.42, 0.87 11.19, 1.24 0.8 M-0.58 31.12 C-0.8 24.94, -0.92 18.74, -0.09 -0.66",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.77 -0.09 L62.43 -0.7 L65.08 32.74 L1.74 33.14",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#c3fae8"
            }}></path><path parentName="g" {...{
              "d": "M-1.65 -0.79 C16.74 0.29, 36.66 1.27, 62.51 -1.73 M0.38 0.18 C23.28 -0.8, 45.48 0.38, 63.21 -0.24 M65.13 1.66 C63.24 10.12, 64.5 17.35, 63.13 31.57 M63.78 -0.95 C64.94 12.42, 63.43 23.54, 63.87 31.36 M63.14 33.23 C39.51 30.88, 16.49 30.74, 0.36 30.37 M63.66 31.45 C49.29 31.59, 34.49 33.02, -0.11 32.69 M-0.05 31.28 C0.43 19.89, -1.64 7.23, -1.8 1.46 M0.54 31.3 C0.78 21.63, 0.64 11.85, 0.47 0.34",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.12 1.05 L63.67 -0.23 L62.52 30.83 L-0.85 32.02",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#96f2d7"
            }}></path><path parentName="g" {...{
              "d": "M-1.76 -0.84 C22.39 -1.05, 48.88 0.81, 62.59 0.54 M-0.98 -0.25 C24.28 1.23, 49.34 -0.16, 63.17 0.68 M64.28 0.07 C61.93 10.45, 65.6 20.83, 62.43 31.24 M63.09 0.83 C63.41 10.68, 63.51 22.09, 64.12 31.27 M65.66 32 C38.88 33.05, 17.1 30.86, -0.1 32.77 M63.67 32.94 C49.36 30.55, 33.74 31.33, -0.77 31.25 M0.76 31.36 C0.15 22.77, -0.59 12.18, -1.27 0.48 M-0.54 31.2 C0.51 20.18, 0.09 7.64, 0.72 -0.47",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.96 1.98 L64.74 -0.96 L64.09 30.13 L1.99 32.65",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#63e6be"
            }}></path><path parentName="g" {...{
              "d": "M-0.83 -1.85 C16.34 -0.56, 32.77 0.54, 65.15 -0.24 M0.74 0.59 C18.04 1.13, 37.38 -0.01, 63.39 0.68 M62.61 1.32 C64.92 7.15, 63.88 14.28, 65.95 32.89 M63.4 -0.29 C64.05 9.86, 63.5 18.8, 63.42 31.36 M62.94 30.37 C42.1 30.99, 21.08 29.73, -1.42 31.2 M63.63 32.61 C45.5 32.68, 27.22 31.91, 0.72 32.2 M-1.6 33.95 C0.06 22.69, -1.72 16.19, 1.16 1.43 M0.04 32.67 C0.01 24.9, -0.44 16.11, 0.53 0.19",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.28 -1.5 L64.9 1.31 L64.51 33.26 L-1.84 30.14",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#38d9a9"
            }}></path><path parentName="g" {...{
              "d": "M-0.32 -0.55 C22.45 0.69, 41.13 -0.55, 63.19 -1.67 M0.05 -0.04 C16.21 0.08, 32.92 0.17, 63.43 -0.28 M64.1 -0.67 C64.66 6.07, 65.3 14.94, 62.43 31.67 M64.61 0.43 C64.69 11.1, 64.74 22.67, 63.7 31.97 M63.99 33.48 C48.5 32.52, 32.29 32.79, 1.58 32.21 M63.61 31.85 C41.48 32.12, 21.13 31.75, 0.22 32.88 M1.92 33.31 C-1.96 23.35, 1.25 11.07, -1.96 -0.79 M-0.38 32.76 C0.46 20.88, 0.53 10.56, 0.43 -0.72",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.37 -1.16 L62.29 1.03 L65.36 31.55 L-0.71 30.59",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#20c997"
            }}></path><path parentName="g" {...{
              "d": "M0.43 1.65 C15.43 -1.08, 34.85 0.27, 65.12 -1.18 M0 -0.96 C23.29 0.28, 46.46 -0.04, 63.94 -0.38 M62.87 0.8 C65 10.65, 62.99 20.47, 65.35 32.23 M63.41 0.16 C62.87 11.46, 64.64 23.94, 64.59 31.82 M62.07 33.2 C40.82 32.39, 19.57 33.33, 1.36 30.15 M64.67 32.53 C39 33.45, 14.42 31.7, 0.12 31.47 M1.51 32.19 C-2.01 23.74, 0.59 16.41, 1.48 1.42 M0.85 32.98 C-0.2 21.99, -0.35 11.79, -0.2 -0.75",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.75 1.56 L63.78 -0.21 L64.47 30.75 L1.86 31.89",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#12b886"
            }}></path><path parentName="g" {...{
              "d": "M1.32 -0.85 C14.26 0.98, 26.91 1.93, 63.38 0.46 M0.1 0.87 C24.28 -0.22, 48.15 0.92, 63.06 -0.97 M62.89 -1.42 C65.29 9.15, 62.32 17.69, 63.61 31.49 M63.34 -0.91 C64.77 9.32, 63.11 20.56, 63.39 32.89 M63.86 32.17 C46.41 32.62, 28.89 32.79, -0.49 32.7 M64.71 32.48 C49.09 32.88, 36.76 31.79, 0.08 32.25 M1.18 31.29 C0.12 27.06, -0.93 17.77, 0.93 -0.01 M0.44 32.94 C-0.95 25.11, -0.07 15.81, -0.1 -0.5",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.45 -0.82 L63.43 0.44 L65.49 32.7 L-1.94 31.83",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#0ca678"
            }}></path><path parentName="g" {...{
              "d": "M-1.74 0.4 C12.06 1.6, 28.69 -0.8, 65.42 1.47 M-0.96 -0.44 C24.55 -1.05, 50.19 0.26, 63.2 -0.79 M64.54 -0.56 C64.35 11.83, 63.34 21.52, 65.59 32.48 M63.66 0.25 C64.44 9.36, 63.21 17.88, 63.91 32.17 M65.6 30.79 C44.84 30.29, 24.92 30.05, -0.04 32.92 M63.5 32.2 C41.25 32.2, 18.58 31.89, -0.13 32.79 M-0.09 30.54 C-1.56 23.44, 0.5 12.33, -1.61 0.94 M0.38 32.56 C0.2 20.93, 0.73 8.61, 0.22 -0.03",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.02 -0.14 L63.8 -1.84 L63.4 30.29 L1.93 32.55",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#099268"
            }}></path><path parentName="g" {...{
              "d": "M1.18 -1.49 C21.64 0.26, 44.46 0.66, 64.78 1.45 M0.74 0.52 C18.42 -0.98, 37.65 0.63, 63.43 0.94 M62.51 -0.86 C63.36 9.92, 65.07 15.38, 62.89 31.83 M63.36 -0.77 C63.35 7.88, 63.58 14.78, 64.39 32.52 M65.09 30.15 C49.95 32.17, 37.58 29.57, 1.54 32.79 M63.71 32.2 C46.3 31.21, 27.41 32.1, -0.1 32.6 M-0.45 33.79 C-1.86 25.96, 1.74 16.89, 0.96 -0.99 M-0.61 32.01 C0.32 19.75, 0 8.52, 0.16 0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 320) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.48 0.02 L64.84 1.9 L65.02 30.45 L1.29 32.67",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#087f5b"
            }}></path><path parentName="g" {...{
              "d": "M1.43 -1.16 C26.66 1.8, 47.84 0.93, 62.07 1.47 M-0.18 0.73 C17.45 -1.02, 35.44 0.61, 64.05 0.19 M65.19 1.97 C63.5 10.53, 62.45 25.58, 64.98 32.77 M63.5 0.02 C64.45 9.11, 64.44 18.5, 64.81 32.54 M64.7 33.84 C40.31 30.86, 20.07 33.44, -0.06 33.98 M63.48 32.99 C48.93 30.97, 34.16 31.7, 1 31.16 M-0.94 30.81 C1.52 21.44, -1.71 8.13, 0.26 -1.2 M0.38 32.56 C0.79 23.28, 0.6 14.77, -0.24 -0.6",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.62 0.71 L63.39 -1.06 L62.38 30.52 L-0.82 32.46",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ebfbee"
            }}></path><path parentName="g" {...{
              "d": "M0.01 -0.77 C26.01 -1.19, 50.27 1.58, 62.85 -0.98 M-0.81 0.44 C18.02 -0.83, 36.03 0.68, 63.17 0.11 M64.67 1.62 C64.49 11.04, 62.44 20.48, 65.45 33.23 M64.01 0.55 C63.34 7.7, 63.58 17.47, 63.71 32.7 M64.73 33.75 C41.67 30.93, 20.9 32.92, -0.38 30.23 M64.98 31.13 C45.07 31.26, 27.75 31.52, -0.24 31.47 M-0.27 30.52 C1.73 22.94, -0.58 12.21, 1.79 -1.48 M0.54 31.02 C0.12 25.03, 0.61 18.1, -0.51 -0.98",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.75 1 L63.12 1.21 L64.4 30.35 L0.87 32.87",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d3f9d8"
            }}></path><path parentName="g" {...{
              "d": "M-0.6 1.16 C20.91 1.69, 47.45 -1.31, 63.16 1.82 M0.32 -0.08 C14.82 -0.99, 30.77 -0.1, 64 -0.37 M62.58 -1.66 C63.37 9.53, 62.31 18.86, 63.44 30.51 M63.18 -0.73 C64.7 12.45, 63.34 24.82, 64.11 32.25 M62.76 32.79 C48.56 30.03, 29.19 32.98, -1.35 32.1 M63.58 32.61 C40.28 32.12, 15.2 31.69, 0.55 32.78 M-1.63 30.09 C-0.65 21, -1.16 7.28, 1.53 0.56 M-0.92 31.56 C-0.88 25.85, 0.79 19.41, 0.46 -0.82",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.47 -1.06 L65.84 -1.58 L63.72 30.82 L0.66 33.97",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#b2f2bb"
            }}></path><path parentName="g" {...{
              "d": "M-0.29 -0.33 C16.16 0.35, 34.82 -2.46, 62.32 -0.73 M0.73 -0.14 C14.1 0.42, 28.31 0.86, 63.57 -0.14 M65.66 1.11 C63.43 7.01, 65.4 16.31, 65.44 32.72 M64.43 -0.37 C64.91 10.27, 63.54 22.91, 63.61 32.11 M63.29 32.35 C50.26 30.46, 39.86 31.04, -0.61 31.47 M63.54 31.5 C46.07 32.24, 29.1 32.99, -0.08 32.83 M1.12 32.52 C-0.27 24.18, -0.56 10.79, 1.72 -1.93 M-0.19 31.61 C-1.19 19.81, -0.1 9.61, 0.06 -0.61",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.4 -0.8 L65.46 -1.81 L63.57 31.93 L0.1 33.65",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#8ce99a"
            }}></path><path parentName="g" {...{
              "d": "M1.12 -1.79 C15.86 -1.16, 32.09 -1.56, 65.94 0.01 M-0.78 -0.83 C17.9 0.22, 34.16 -0.49, 64.83 -0.91 M64.74 -1.19 C64.96 10.5, 62.35 20.83, 63.28 31.6 M64.61 -0.14 C63.74 8.81, 64.36 18.55, 64.76 31.33 M65.52 33.54 C47.44 34.07, 28.99 33.43, -1.37 31.04 M63.28 32.93 C43.12 32.2, 21.97 32.74, -0.07 32.97 M1.5 33.07 C1.59 25.11, 1.1 19.25, -1.14 0.65 M-0.5 31.41 C-0.87 23.49, 0.5 14.35, 0.11 -0.19",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.25 -0.03 L65.21 0.1 L64.13 33.19 L-0.18 32.33",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#69db7c"
            }}></path><path parentName="g" {...{
              "d": "M-0.11 -1.71 C26.65 2.1, 50.62 1.25, 64.15 -0.58 M0.57 -0.49 C18.18 -0.21, 36.17 0.11, 63.46 -0.93 M63.92 1.83 C64.52 8.53, 65.04 17.7, 63.78 33.48 M64.21 -0.6 C63.2 7.44, 63.52 13.01, 64.05 31.24 M62.88 30.25 C45.03 31.76, 28.1 31.8, 1.09 32.56 M64.08 31.01 C45.37 31.93, 26.1 30.89, -0.9 32.8 M-1.37 32.26 C1.46 26.28, -0.05 18.28, -0.32 -1.44 M0.12 31.03 C0.29 21.82, -0.49 13.94, 0.91 0.35",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.97 -0.31 L62.3 -1.76 L63.45 30.39 L-1.84 33.17",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#51cf66"
            }}></path><path parentName="g" {...{
              "d": "M-0.13 -0.61 C14.82 1.81, 32.28 0.65, 64.04 -1.74 M-0.37 0.7 C19.57 0.13, 37.01 -0.63, 64.49 -0.53 M65.67 -0.11 C65.08 11.38, 63.79 19.48, 64.86 30.53 M64.91 -0.77 C64 9.42, 63.82 18.59, 64.32 31.24 M63.54 31.78 C44.41 31.27, 22.4 34.03, 1.76 30.87 M63.49 32.56 C48.08 32.19, 32.08 32.71, -0.69 32.95 M0.9 32.08 C1.26 21.1, -0.21 13.53, -1.97 1.88 M0.24 32.11 C-0.74 23.24, -0.47 14.45, 0.23 0.62",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.3 0.62 L63.4 1.61 L65.59 32.55 L-0.07 30.26",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#40c057"
            }}></path><path parentName="g" {...{
              "d": "M1.76 -1.72 C20.6 -2.08, 37.57 -0.17, 64.81 0.91 M-0.68 0.89 C17.48 -1.26, 35.92 -0.25, 63.02 0.99 M64.7 -1.63 C64.72 11.75, 65.02 25.39, 64.04 31.58 M64.29 0.82 C63 6.77, 64.71 14.54, 63.78 31.78 M63.6 33.14 C38.83 30.67, 12.91 31.5, 1.03 32.55 M64.34 31.79 C45.79 30.79, 29.09 31.05, -0.59 32.46 M0.97 32.22 C0.31 22.34, 1.49 15.06, -0.67 -1.42 M-0.6 32.03 C0.43 25.04, -0.55 15.86, 0.82 -0.34",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.19 1.52 L65.67 -0.13 L65.97 33.88 L-1.25 33.08",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#37b24d"
            }}></path><path parentName="g" {...{
              "d": "M1.55 -0.76 C25.16 0.77, 48.72 -1.3, 65.39 -0.15 M-0.64 0.38 C18.67 -0.18, 36.99 0.02, 64.69 -0.27 M63.27 -1.02 C62.42 11.54, 65.65 18.28, 64.74 31.44 M63.8 -0.88 C63.84 8.78, 63.96 18.22, 63.12 32.9 M63.23 31.14 C44.42 31.19, 26.45 30.97, -0.78 33.62 M63.27 31.15 C43.04 30.81, 20.98 32.43, 0.22 31.73 M-0.49 33.99 C0.83 24.23, -0.32 13.25, 1.27 -0.86 M0.11 32.24 C-0.18 19.02, -0.27 8.11, 0.96 -0.07",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.7 -0.73 L62.27 -0.44 L65.53 31.75 L1.05 32.15",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#2f9e44"
            }}></path><path parentName="g" {...{
              "d": "M-1.02 0.58 C25.61 -0.57, 46.45 0.92, 63.2 0.44 M-0.24 0.93 C19.39 -0.69, 39.62 0.48, 63.83 0.09 M64.27 -0.34 C62.99 12.32, 64.83 23.09, 63.19 30.67 M64.42 0.2 C63.89 8.19, 65.1 14.7, 63.51 31.61 M64.73 31.64 C47.62 31.06, 31.85 30.85, -1.35 30.54 M63.47 31.48 C48.37 32.71, 31.62 32.93, 0.74 32.26 M-0.5 31.06 C-1.34 24.89, -1.6 15.44, 0.89 0.82 M-0.69 31.72 C-0.48 21.85, 0.8 11.11, 0.71 -0.56",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 358) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.81 1.55 L64.23 -1.63 L63.63 30.37 L1.61 33.37",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#2b8a3e"
            }}></path><path parentName="g" {...{
              "d": "M-1.24 0.96 C16.6 0.35, 34.62 -1.29, 65.89 0.99 M-0.77 0.8 C15.9 -0.32, 33.01 -0.04, 64.34 -0.95 M62.93 0.49 C65.09 11.87, 65.72 23.82, 63.07 30.45 M64.37 0.68 C63.17 6.09, 63.58 14.2, 63.44 31.4 M63.77 30.65 C51.13 33.78, 35.31 33.61, 0.37 30.95 M64.72 31.33 C48.5 31.57, 31.2 33, -0.77 31.35 M-0.66 30.55 C1.85 25.62, -0.21 18.25, 0.1 -0.39 M0.06 32.92 C0.57 25.01, 1.01 16.64, 0.16 0.02",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.66 -1.45 L63.93 0.06 L64.92 32.87 L1.17 32.4",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f4fce3"
            }}></path><path parentName="g" {...{
              "d": "M1.5 -0.47 C19.22 -0.73, 36.72 -2.14, 65.45 -0.29 M0.51 -0.88 C13.44 0.21, 28.19 0.03, 63.4 0.34 M65.83 0.68 C64.14 8.9, 65.59 18.97, 65.94 32.95 M63.13 -0.25 C63.97 9.65, 64.12 20.01, 63.39 31.18 M65.42 31.41 C46.57 31.28, 26.93 32.4, -0.24 30.33 M63.4 32.47 C50.23 32.49, 34.59 32.04, 0.74 31.51 M-0.3 32.97 C-1.08 23.01, -0.37 17.83, 0.1 -1.88 M1 31.82 C-0.22 20.24, 1.07 7.63, 0.24 0.88",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.39 -1.27 L63 0.88 L62.43 30.24 L1.47 31.63",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e9fac8"
            }}></path><path parentName="g" {...{
              "d": "M0.56 -0.47 C21.41 0.41, 40.45 -0.84, 65.31 -0.91 M-0.72 0.99 C23.17 0.07, 46.74 -0.68, 64.28 -0.56 M65.26 0.03 C64.66 7.58, 64.65 13.98, 62.84 30.88 M63.93 -0.21 C63.11 10.1, 64.53 19.66, 64.3 31.72 M65.03 30.4 C47.68 30.07, 31.02 32.97, -0.04 32.54 M63.94 31.66 C43.19 30.9, 20.43 32.36, -0.2 31.11 M1.41 33.71 C0.16 17.71, 0.8 7.53, -1 0.41 M0.3 31.17 C-0.32 22.12, 0.07 13.34, -0.36 -0.48",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.64 1.72 L63.66 -1.3 L65.25 31.39 L1.42 30.87",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d8f5a2"
            }}></path><path parentName="g" {...{
              "d": "M-1.5 1.63 C23.48 -0.44, 47.41 -1.49, 62.52 0.66 M-0.83 0.11 C15.83 -0.03, 31.05 1.02, 63.8 -0.28 M65.26 0.72 C63.91 11.1, 62.84 22.2, 64.59 30.21 M64.83 0.64 C64.66 11.28, 64.4 20.17, 63.74 32.26 M62.92 31.56 C48.42 31.06, 29.84 33.58, 0.52 32.62 M63.85 32.98 C40.16 30.9, 16.42 31.26, -0.08 31.57 M-0.09 33.7 C1.91 22.98, 0.38 14.45, -1.89 -0.11 M-0.57 31.11 C-0.89 24.52, -1.17 14.45, 0.93 -0.91",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.16 -0.44 L64.8 1.72 L64.39 31.19 L1.93 31.24",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#c0eb75"
            }}></path><path parentName="g" {...{
              "d": "M0.47 0.79 C21.4 -1.94, 45.3 -1.82, 63.6 -0.44 M-0.24 -0.47 C13.72 -1.14, 27.26 0.42, 63.23 0 M63.82 1.77 C65.51 12.8, 65.29 22.17, 65.29 33.04 M64.97 -0.15 C63.03 13.34, 63.27 24.63, 63.12 32.93 M62.19 32 C43.54 30.65, 24.04 32.85, 1.74 30.38 M64.26 32.16 C40.88 32.13, 16.82 32.06, -0.5 32.49 M1.54 32.24 C0.22 22.63, 1.89 12.71, -1.54 -1.67 M-0.21 31.8 C-0.92 23.22, -0.94 12.71, -0.17 -0.55",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.35 1.19 L64.26 1.33 L64.74 30.14 L-0.81 31.64",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#a9e34b"
            }}></path><path parentName="g" {...{
              "d": "M0.84 -0.59 C22.03 -1.96, 41.17 1.5, 63.13 -1.94 M-0.96 -0.94 C22.08 -0.14, 44.32 0.98, 63.57 0.95 M62.97 -0.28 C62.74 9.98, 65.39 20.91, 64.14 31.52 M64.25 -0.86 C64.21 12.7, 64.41 24.37, 64.03 32.18 M62.7 31.29 C48.74 32.53, 35.39 32.82, 0.47 32.93 M64.5 32.74 C40.98 33.34, 18.75 32.82, -0.47 31.08 M-1.9 30.11 C-0.85 18.8, -0.07 9.72, -1.06 0.44 M0.86 31.67 C0.32 25.82, -0.12 18.35, -0.01 0.87",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.44 -1.19 L65.17 1.89 L65.5 30.05 L-1.96 33.36",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#94d82d"
            }}></path><path parentName="g" {...{
              "d": "M1.9 0.6 C14.72 0, 27.56 -0.47, 63.41 -0.3 M0.15 0.53 C23.83 -0.14, 47.59 -0.84, 64.57 -0.7 M64.11 -0.05 C65.45 8.77, 63.18 12.58, 62.51 30.82 M63.32 0.15 C63.86 7.29, 62.84 15.89, 63.8 31.45 M64.11 32.42 C44.13 32.56, 22.73 33.09, 1.58 31.3 M64.02 31.5 C48.66 31.88, 33.84 31.18, -0.25 31.45 M-1.97 32.69 C-1.85 20.45, -1.13 7.05, -0.52 0.54 M-0.24 31.59 C-0.4 25.68, -0.64 19.24, 0.96 0.04",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.53 0.34 L65.54 -1.96 L63.71 32.65 L1.66 31.87",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#82c91e"
            }}></path><path parentName="g" {...{
              "d": "M-0.29 0.03 C14.4 1.76, 33.87 1.18, 62.82 -1.28 M-0.14 -0.07 C20.82 -0.54, 43.72 0.44, 64.08 0.3 M64.33 -1.55 C62.94 5.17, 63.83 12.77, 64.88 31.3 M63.65 0.41 C63.05 10.99, 63.13 22.4, 64.39 32.57 M62.29 33.61 C39.11 34.26, 14.23 32.35, -0.82 33.74 M64.57 32.76 C50.03 32.37, 36.27 33.03, 0.95 31.54 M-0.25 30.66 C0.39 23.87, 1.21 16.68, -0.55 0.02 M0.3 31.15 C0.35 25.38, -0.77 16.66, -0.84 -0.78",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.18 -0.73 L65.69 -0.47 L63.64 33.61 L-0.79 33.51",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#74b816"
            }}></path><path parentName="g" {...{
              "d": "M1.8 0.79 C13.86 -1.48, 26.89 -1.29, 65.46 1.98 M0.94 0.22 C18.5 0.2, 37.36 0.4, 64.25 -0.4 M62.76 -0.4 C65.47 11.31, 64.57 23.26, 63.77 30.08 M64.81 0.41 C62.86 12, 64.62 24.08, 63.33 32.67 M64.08 32.51 C41.7 30.44, 18.09 30.06, 1.61 30.56 M64.64 31.52 C42.85 32.26, 24.03 32.29, -0.98 32.99 M1.44 33.24 C1.69 24.41, 1.56 18.12, -1.53 -0.57 M0.4 31.81 C0.68 23.09, -0.93 16.23, 0.1 -0.01",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.22 1.74 L64.59 -0.25 L64.74 30.94 L-1.95 33.73",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#66a80f"
            }}></path><path parentName="g" {...{
              "d": "M1.35 0.32 C13.79 0.73, 31.78 -0.88, 63.28 -1.47 M0.59 -0.33 C16.88 1.22, 34.85 0.71, 63.85 0.61 M63.64 1.09 C63.92 14.12, 65.46 23.99, 63.65 30.01 M63.58 0.87 C63.4 9.99, 64.47 20.41, 63.28 31.04 M63.57 32 C42.28 32.22, 22.96 31.13, -0.38 31.06 M64.47 32.23 C47.97 32.17, 32.09 31.41, -0.19 32.27 M-1.68 30.41 C0.17 20.98, 0.54 8.14, 1.54 0.93 M-0.05 32.16 C1.02 21.61, 0.15 11.85, 0.49 -0.39",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 396) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.74 0.47 L63.64 0.48 L63.56 32.08 L0.83 30.29",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#5c940d"
            }}></path><path parentName="g" {...{
              "d": "M1.63 -0.77 C18.48 0.43, 36.93 -0.34, 63.15 1.42 M0.65 0.85 C23.63 0.45, 47.02 0.97, 64.62 -0.97 M62.71 -1.15 C62.46 6.42, 64.25 14.89, 64.86 30.25 M63 -0.04 C63.69 8.93, 63.04 17.38, 63.73 31.58 M62.43 30.51 C37.18 33.72, 14.27 32.7, 0 31.15 M63.19 31.27 C45.46 32.13, 28.79 33.26, 0.5 32.56 M1.19 32.35 C-1.13 21.58, -0.34 11.76, -0.64 -0.98 M0.43 31.01 C0.7 24.35, 0.18 15.68, -0.84 0.61",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.99 0.52 L62.74 0.52 L63.63 33.2 L-1.43 31.25",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fff9db"
            }}></path><path parentName="g" {...{
              "d": "M-1.18 0.3 C26.83 0.65, 49.22 -1.93, 63.27 0.5 M0.38 -0.03 C22.07 -0.11, 44.91 0.2, 64.72 0.11 M64.35 -1.46 C64.65 4.94, 63.41 15.07, 63.64 33.31 M64.76 0.68 C63.27 10.96, 64.39 23.15, 63.92 31.89 M64.26 33.18 C49.91 32.85, 37.12 34.26, 1.29 30.74 M64.37 32.05 C41.87 30.74, 20.19 32.14, 0.8 32.91 M0.17 30.51 C1.26 23.85, 0.65 13.32, -1.36 1.64 M0.95 32.92 C0.45 24.11, 0.26 16.41, -0.13 -0.18",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.84 -1.89 L64.17 -1.08 L63.38 33.94 L-0.57 32.87",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fff3bf"
            }}></path><path parentName="g" {...{
              "d": "M-0.45 0.76 C19.57 1.41, 41.28 -1.89, 62.66 -0.22 M0.3 0.13 C20.43 0.17, 40.08 -0.94, 64.78 0.87 M65.99 0.32 C65.67 6.02, 62.56 15.84, 62.27 32.16 M64.48 -0.68 C64.89 9.77, 63.95 18.26, 63.85 31.29 M62.17 30.24 C47.56 33.07, 34.84 30.08, -1.27 32.59 M63.22 31.56 C46.75 31.08, 29.64 32.56, -0.09 31.87 M-1.03 33.9 C-1.39 23.55, -2.06 15.29, -0.76 1.67 M-0.79 31.77 C-0.27 21.7, 1.24 12.51, 0.13 -0.99",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.89 -0.23 L64.4 -0.64 L64.22 31.31 L0.14 30.84",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffec99"
            }}></path><path parentName="g" {...{
              "d": "M-1.69 -0.36 C21.82 -0.14, 44.98 1.92, 65.25 1.92 M0.42 -0.22 C24.9 -0.92, 50.02 -0.77, 64.19 -0.58 M62.24 -1.38 C64.99 7.17, 66.18 15.03, 62.49 31.84 M64.1 -0.9 C63.34 7.83, 64.35 16.59, 64.32 31.39 M63.57 30.05 C50.89 32.59, 36.02 31.47, 1.52 33.48 M64.88 31.38 C39.72 32.52, 14.55 31.52, 0.23 31.92 M0.53 31.52 C-0.92 23.43, -1.16 16.26, -0.63 0.44 M0.93 31.94 C-0.56 22.06, 0.72 11.39, 0.27 -0.48",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.08 -0.71 L64.44 -0.89 L63.48 31.97 L-0.81 31.44",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffe066"
            }}></path><path parentName="g" {...{
              "d": "M0.8 -0.46 C15.76 -2.15, 31.54 0.21, 63.24 -1.21 M0.54 -0.15 C14.7 -0.55, 27.45 -0.76, 64.53 -0.91 M64.29 1.29 C62.15 7.79, 63.12 15.2, 64.28 30.13 M64.77 0.75 C64.53 12.54, 64.44 22.8, 64.43 31.43 M63.59 32.88 C50.14 31.04, 33.13 32.92, 1.96 33.83 M64.87 32.03 C49.9 30.42, 36.32 32.1, -0.98 31.09 M-0.69 32.98 C1.34 23.14, -1.39 17.77, 1.56 0.14 M0.93 31.9 C0.93 20.38, -0.08 11.06, -0.56 -0.9",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.89 0.85 L63.93 0.08 L64.99 31.6 L0.27 32.44",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffd43b"
            }}></path><path parentName="g" {...{
              "d": "M0.87 1.69 C22.61 -0.87, 48.27 1.24, 64.08 -0.77 M0.02 -0.79 C16.76 0.55, 34.67 0.27, 63.5 0.93 M64.66 -0.93 C65.44 5.11, 65.49 12.87, 62.56 33.66 M64.6 -0.51 C62.8 8.13, 63.24 15.08, 63.72 32.36 M64.86 30.32 C45.15 31.44, 26.72 32.19, -0.11 30.31 M63.98 32.03 C48.64 32.27, 31.79 32.24, 0.11 31.61 M-1.03 30.32 C0.79 24.15, 2.21 18.97, 1.73 -0.47 M-0.49 31.87 C-0.92 23.63, 0.53 15.89, 0.91 0.06",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.21 -0.74 L64.23 0.39 L63.49 32.22 L1.37 30.65",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fcc419"
            }}></path><path parentName="g" {...{
              "d": "M1.91 -1.31 C22.25 -0.18, 43.01 0.04, 62.45 -0.84 M-0.74 0.3 C20.37 1.07, 41.06 0.37, 63.93 0.97 M64.31 1.15 C65.39 7.17, 63.59 14.58, 63.88 30.66 M64.71 0.31 C63.75 7.35, 63.38 13, 64.04 32.87 M62.39 33.43 C49.75 31.25, 37.1 31.33, -0.57 31.78 M63.46 31.02 C45.6 31.91, 25.61 31.72, -0.6 31.6 M1.89 32.7 C-1.24 21.88, -0.49 12.89, -1.26 1.79 M0.36 31.52 C-0.94 20.23, -0.08 7.26, -0.51 -0.12",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.58 -0.35 L62.22 -1.16 L65.11 30.82 L-0.5 31.24",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fab005"
            }}></path><path parentName="g" {...{
              "d": "M1.55 -0.41 C17.21 -0.2, 33.4 -0.46, 65.17 -1.5 M0.63 -0.42 C25.82 0.6, 51.33 -0.22, 64.61 -0.38 M62.03 -0.85 C65.15 13.34, 63.65 21.45, 64.54 30.86 M63.17 -0.79 C63.78 10.04, 63.35 19.29, 64.07 32.91 M63.94 31.06 C39.21 32.94, 19.12 31.75, -1.63 30.9 M64.38 32.56 C47.83 32.03, 31.87 31.22, -0.74 32.47 M-1.19 33.57 C1.22 25.09, -1.4 16.01, 0.89 -0.48 M-0.72 31.65 C-0.24 20.29, -0.15 6.93, -0.41 0.78",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.78 -1.66 L65.97 0.04 L64.81 33.03 L1.54 30.63",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f59f00"
            }}></path><path parentName="g" {...{
              "d": "M-1.27 -1.53 C22.84 -1.56, 44.87 0.01, 65.21 1.96 M0.6 0.97 C15.6 -0.72, 32.23 0.59, 63.5 -0.04 M65.88 0.11 C64.57 13.89, 64.25 24.41, 63.9 33.08 M63.06 -0.25 C64 12.6, 63.38 23.57, 64.77 32.15 M64.91 30.32 C50.09 31.7, 39.85 30, -0.76 33.63 M64.94 32.41 C42.43 31.94, 23.21 33.43, -0.73 32.99 M-0.99 32.76 C0.62 19.72, 0.42 7.68, 1.12 1.22 M-0.8 32.04 C0.76 19.45, 1.07 7.2, -0.52 -0.52",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.13 -1.43 L66 -1.75 L62.64 30.66 L-0.54 31.72",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f08c00"
            }}></path><path parentName="g" {...{
              "d": "M0.69 -1.09 C12.53 -1.13, 28.91 1.13, 63.64 -0.15 M-0.34 0.25 C12.74 -0.01, 25.58 -0.82, 64.28 0.97 M64.11 -1.77 C62.54 8.2, 63.26 15.13, 62.94 31.67 M63.34 -0.72 C63.98 12.18, 63.97 24.35, 64.6 31.61 M63.71 33.81 C41.51 32.17, 19.08 33.57, -1.33 30.18 M64.2 31.64 C38.04 31.89, 12.65 32.1, 0.89 32.79 M0.6 33.81 C1.91 17.98, 1.35 6.74, 0.84 -1.77 M-0.39 32.92 C-0.39 26.42, -0.02 18.56, -0.99 0.82",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 434) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.48 -0.7 L65.41 1.83 L63.08 31.89 L0.27 31.17",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e67700"
            }}></path><path parentName="g" {...{
              "d": "M-0.35 1.36 C24.15 -0.53, 46.13 -1.58, 62.83 0.47 M-0.75 -0.45 C17.4 0.96, 32.78 0.62, 63.87 -0.81 M65.45 -0.46 C62.06 11.83, 63.31 21.75, 65.35 32.8 M64.8 -0.3 C64.69 9.23, 63.24 17.27, 63.94 32.62 M64.06 30.84 C46.49 30.6, 29.77 32.96, -1.83 30.79 M64.4 32.68 C44.43 31.43, 24.87 30.87, 0.05 32.59 M0.88 33.71 C0.38 18.88, -2.01 8.61, -0.19 0.05 M0.44 31.19 C-0.45 20.82, -0.58 11.78, -0.16 -0.78",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(16 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.02 -0.33 L64.02 0.69 L62.85 32.22 L0.57 33.43",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fff4e6"
            }}></path><path parentName="g" {...{
              "d": "M0.82 -0.92 C21.93 1.22, 47.59 1.73, 62.97 -1.74 M0.75 -0.07 C13.91 0.28, 29.4 0.21, 64.73 0.05 M65.09 0.58 C65.46 7, 62.54 15.61, 64.99 31.16 M63.35 0.79 C64.86 10.47, 63.92 21.15, 63.74 31.4 M62.16 32.41 C40.42 32.52, 21.64 30.41, -0.1 30.24 M63.09 31.24 C42.37 32.43, 18.92 31.69, -0.9 32.83 M-0.21 30.94 C-0.21 17.55, 0.16 6.43, 1.53 1.06 M-0.12 31.49 C0.34 19.93, 0.08 6.81, -0.43 0.1",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(86 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.55 -0.89 L63.9 -1.09 L63.71 33.51 L-0.54 32.76",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffe8cc"
            }}></path><path parentName="g" {...{
              "d": "M-0.99 1.44 C22.03 2.23, 45.32 1.31, 62.47 1.87 M0.86 -0.17 C19.01 0.69, 37.13 0.8, 63.61 -0.89 M63.85 -0.56 C62.35 11.21, 65.1 24.56, 63 30.01 M63.47 0.24 C64.09 9.34, 64.28 16.99, 63.13 31.84 M64.66 33.93 C50.96 32.23, 35.95 31.19, -0.81 31.53 M63.64 31.3 C49.87 31.64, 36.36 32.34, 0.75 31.07 M-0.12 31.18 C-0.94 19.96, 0.49 10.3, 1.49 0.65 M0.34 32.56 C0.01 23.27, 0.6 16.04, 1 0.09",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(156 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.86 -0.24 L62.07 1.34 L64.12 30.18 L1.9 32.98",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffd8a8"
            }}></path><path parentName="g" {...{
              "d": "M0.99 -0.49 C24.22 1.37, 48.84 0.71, 65.94 0.85 M0.77 0.67 C24.16 0.77, 46.6 -0.26, 64.27 0.5 M62.69 0 C65.63 9.29, 63.77 17.45, 64.05 33.48 M64.4 0.7 C64.75 11.12, 63.68 19.61, 63.91 32.96 M62.45 32.5 C39.39 33.13, 18.14 30.46, 1.78 31.74 M63.1 32.89 C48.53 32.68, 35.12 32.64, 0.01 31.85 M-1.74 33.25 C1.28 21.7, -1.21 13.25, 1.55 1.33 M0.96 32.04 C0.36 20.75, -0.25 8.05, -0.21 0.94",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(226 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-0.55 0.36 L64.34 -1.98 L64.89 31.67 L-0.51 30.08",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffc078"
            }}></path><path parentName="g" {...{
              "d": "M-1.45 1.3 C22.93 -1.77, 47.31 1.2, 65.66 -0.69 M-0.9 0.77 C17.87 0.23, 35.15 0.53, 64.49 0.61 M64.48 -1.14 C65.81 7.52, 65.79 15.95, 65.99 30.32 M63.16 0.43 C63.86 10.79, 64.87 22.67, 63.22 32.64 M63.87 33.19 C40.61 32.03, 18.32 33.84, -0.73 32.86 M64.58 32.09 C47.27 32.47, 28.8 31.75, -0.42 31.7 M1.85 31.51 C0.08 21.46, -0.44 12.2, 0.95 1.9 M-0.84 31.61 C-0.97 20.46, 0.61 9.55, 0.47 -0.25",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(296 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.9 1.51 L62.17 -1.58 L64.34 31.48 L-0.36 32.11",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ffa94d"
            }}></path><path parentName="g" {...{
              "d": "M-1.32 -1.6 C25.66 -0.33, 46.84 0.22, 64.22 1.36 M-0.85 0.91 C24.97 0.66, 49.57 0.31, 63.15 -0.94 M64.58 -1.86 C63.65 6.92, 63.76 12.31, 62.49 30.8 M64.38 0.32 C63.28 10.59, 64.97 20.41, 63.4 31.77 M63.16 33.12 C42.59 32.98, 21.25 30.06, -0.84 31.35 M64.38 32.9 C48.91 33.18, 32.5 31.9, -0.78 31.5 M-0.27 32.39 C0.84 20.3, 1.94 11.9, -0.27 -1.73 M0.59 31.03 C0.61 24.91, -0.03 18.21, 0.47 0.79",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(366 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.5 -1.37 L64.89 -1.25 L62.02 31.94 L-1.88 30.5",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#ff922b"
            }}></path><path parentName="g" {...{
              "d": "M-0.08 -1.97 C15.84 0.08, 34.54 -0.58, 63.78 -0.66 M-0.66 -0.6 C20.11 -0.45, 40.79 -0.58, 64.62 0.95 M63.33 1.68 C62.95 8.51, 64.46 14.93, 63.91 31.52 M64.23 -0.35 C63.92 11.84, 64.33 25.09, 64.04 31.7 M65.37 32.02 C46.21 33.58, 28.49 33.68, -0.74 30.21 M64.33 32.39 C42.05 31.26, 19.91 30.94, -0.87 31.98 M1.64 33.83 C-1.37 20.68, 1.27 10.56, -0.73 0.51 M0.17 31.84 C-0.88 19.77, -0.17 6.55, 0.18 0.2",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(436 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M0.76 1.96 L64.22 -0.81 L65.56 32.37 L1.06 31.1",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#fd7e14"
            }}></path><path parentName="g" {...{
              "d": "M0.65 -1.69 C18.27 -1.19, 32.96 -0.39, 63.13 1.39 M0.2 -0.08 C23.2 -0.17, 45.73 -0.48, 64.36 0.14 M64.07 -1.7 C62.46 9.85, 61.98 19.07, 63.71 30.68 M64.71 0.75 C63.26 10.59, 63.68 20.41, 63.93 31.97 M63.45 32.89 C45.31 34.11, 28.29 32.62, -1.71 30.65 M63.42 32.33 C38.76 33.47, 14.19 32.89, 0.88 31.67 M-0.6 33.03 C1.66 25.59, -0.33 17.08, 0.5 1.06 M-0.46 32.35 C0.66 24.27, -0.44 16.85, -0.02 -0.77",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(506 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M-1.27 1.39 L63.64 1.95 L64.88 32.66 L-0.92 30.71",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#f76707"
            }}></path><path parentName="g" {...{
              "d": "M-1.1 0.29 C18.01 1.37, 35.23 0.42, 62.05 0.76 M-0.55 0.03 C20.34 -0.13, 39 -0.93, 63.82 0.39 M65.97 -0.83 C63.11 8.44, 63.2 20.21, 65.19 33.58 M64.06 -0.15 C64.33 8.24, 64.13 17.93, 63.57 32.92 M64.34 31.34 C42.51 34.34, 25.64 33.27, 0.47 30.66 M63.89 31.26 C43.87 32.1, 22.21 32.45, 0.86 32.31 M1.98 32.37 C-1.04 24.94, 1.73 15.94, 0.27 -1.69 M-0.1 32.28 C0.58 22.41, -0.41 13.44, 0.62 -0.02",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(576 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.47 1.26 L64.14 -1.97 L63.9 33.61 L1.79 30.58",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#e8590c"
            }}></path><path parentName="g" {...{
              "d": "M-0.84 -1.83 C22.78 0.38, 46.19 -1.03, 62.45 1.04 M0.85 -0.26 C17.93 -0.39, 34.86 0.92, 64.6 -0.54 M62.27 -1.68 C63.88 10.55, 62.69 22.39, 63.46 31.02 M63.57 -0.94 C64.42 11.05, 64.92 21.47, 63.93 31.4 M65.06 30.11 C48.55 33.44, 32.98 32.62, -1.08 32.45 M63.54 31.11 C44.93 31.89, 25.37 31.32, 0.29 32.46 M-0.58 33.03 C1.42 22.81, -0.04 13.16, -1.06 1.02 M0.43 31.56 C0.3 21.19, -0.49 13.19, 0.74 -0.53",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g><g parentName="svg" {...{
            "strokeLineCap": "round",
            "transform": "translate(646 472) rotate(0 32 16)"
          }}><path parentName="g" {...{
              "d": "M1.47 1.13 L64.36 0.56 L62.81 32.27 L0.89 32.41",
              "stroke": "none",
              "strokeWidth": "0",
              "fill": "#d9480f"
            }}></path><path parentName="g" {...{
              "d": "M1.08 1.69 C23.28 2.1, 46.18 -0.78, 64.93 0.37 M0.84 0.36 C22.59 -1.19, 43.44 -1.22, 63.1 0.94 M63.57 -0.67 C62.49 8.01, 65.41 20.92, 65.38 31.49 M64.67 0.6 C64.1 11.65, 65.12 22.64, 63.13 32.21 M64.44 30.77 C43.51 32.46, 26.89 33.12, -0.31 33.54 M64.84 31.16 C39.78 32.92, 15.94 31.45, 0.83 31.14 M-0.18 33.61 C1.48 20.93, 0.28 11.32, 0.89 1.82 M-0.65 32.8 C-0.85 20.06, 0.1 9.19, 0.34 0.55",
              "stroke": "#343a40",
              "strokeWidth": "1",
              "fill": "none"
            }}></path></g></svg></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      